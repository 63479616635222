import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";
import { IoIosCall } from "react-icons/io";
import { BsWallet } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { IoIosLogOut } from "react-icons/io";
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import { FaCheck } from "react-icons/fa6";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const history = useHistory();

  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.Name);
        setCode(res.data.referral);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [Name, setName] = useState();
  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        // console.log(res.data)

        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      // // console.log(access_token)
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      // console.log(formData)
      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }

    // await axios.patch(baseUrl+`challange/result/${path}`,
    //   {
    //     status: status
    //   },
    //   { headers })
    //   .then((res) => {
    //     // getPost(res.data)
    //     // console.log(res.data);
    //     history.push("/landing")
    // }).catch((e) => {
    //     // console.log(e);
    //   })
  };
  //avatar

  useEffect(() => {
    UserALL();
  }, []);

  const [inputOpen, setInputOpen] = useState(false);
  const [Email, setEmail] = useState();
  const toggleInput = () => {
    setInputOpen(!inputOpen);
  };

  // Email validations
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValidFormat = emailRegex.test(newEmail);

    const endsWithCom = newEmail.toLowerCase().endsWith(".com");

    setIsValidEmail(isValidFormat && endsWithCom);
  };

  //Update email

  const UpdateEmail = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Email,
        },
        { headers }
      )
      .then((res) => {
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setEmail(res.data);
          setInputOpen(false);
          window.location.reload();
        }
      });
  };

  return (
    <>
      <Header user={profile} />
      <div
        className="leftContainer"
        style={{
          minHeight: "100vh",
          paddingTop: "80px",
          paddingBottom: "60px",
        }}
      >
        <div className="  profile_div_main_box">
          <div>
            <label>
              <input
                className="d-none"
                type="file"
                onChange={(e) => Result(e.target.files[0])}
                accept="image/*"
                required
              />
              <picture>
                {/* {image} */}
                {/* {console.log(process.env.PUBLIC_URL)} */}
                {profile && profile.avatar ? (
                  <img
                    height="80px"
                    width="80px"
                    src={baseUrl + `${profile && profile.avatar}`}
                    alt=""
                    style={{ borderRadius: "50px" }}
                  />
                ) : (
                  <img
                    height="80px"
                    width="80px"
                    src={process.env.PUBLIC_URL + `/user.png`}
                    alt=""
                    style={{ borderRadius: "50px" }}
                  />
                )}
              </picture>
            </label>
          </div>

          <div className="profile_details ">
            <h2>
              {profile && profile.Name}
              <img
                className={`ml-2`}
                width="20px"
                src={process.env.PUBLIC_URL + "/Images/icon-edit.jpg"}
                alt=""
                onClick={() => {
                  setPortcss(css.inactive_tab);
                  setPortcss1(css.active_tab);
                }}
              />
            </h2>
            <div className={`text-bold my-3 ${portcss1}`}>
              <div
                className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                style={{ verticalAlign: "bottom" }}
              >
                <div
                  className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                >
                  <input
                    aria-invalid="false"
                    type="text"
                    maxLength={"20"}
                    className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                    placeholder="Enter Username"
                    value={Name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <img
                className="ml-2"
                width="20px"
                src={
                  process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"
                }
                alt=""
                onClick={() => {
                  setPortcss(css.active_tab);
                  setPortcss1(css.inactive_tab);
                  UpdateProfile(Id);
                }}
              />
            </div>
            <h3>
              <IoIosCall className="card_icons" /> {profile && profile.Phone}
            </h3>
            {/* email */}
            <Link className={`d-flex align-items-center  email_verified`}>
              <HiOutlineMail className="card_icons" />{" "}
              <div className="Profile_mytext__ZlZJy">
                {profile?.Email === null ? (
                  <div className="email_input">Email Verification</div>
                ) : (
                  <h3>{profile?.Email}</h3>
                )}
              </div>
              {profile?.Email === null ? (
                <img
                  className={`ml-2`}
                  width="20px"
                  src="/images/icon-edit.jpg"
                  alt=""
                  onClick={toggleInput}
                />
              ) : profile?.Email !== null ? (
                <span onClick={toggleInput}>
                  {" "}
                  <img
                    className={`ml-2`}
                    width="20px"
                    src="/images/icon-edit.jpg"
                    alt=""
                    onClick={toggleInput}
                  />
                </span>
              ) : (
                ""
              )}
            </Link>
            <div
              className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
            >
              {inputOpen && (
                <div>
                  <input
                    aria-invalid={!isValidEmail}
                    type="email"
                    className="email_input_inner"
                    placeholder="Enter your email"
                    value={Email}
                    onChange={handleEmailChange}
                  />

                  <img
                    className="ml-2 profile_name_edit"
                    width="20px"
                    src="/images/select-blue-checkIcon1.png"
                    alt=""
                    onClick={() => {
                      toggleInput();
                      if (isValidEmail) {
                        UpdateEmail(Id);
                      } else {
                        Swal.fire({
                          title: "please enter your email address",
                          icon: "error",
                          confirmButtonText: "OK",
                        });
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div class='battle_devider'></div> */}

        <div className="complete_profile_box">
          <div className="profile_headings">Complete Profile</div>

          <div className="complete_kyc_btn_inner">
            <div className="complete_kyc_btn">
              <Link to="/Addcase">
                <picture className="">
                  <img
                    width="20px"
                    src={process.env.PUBLIC_URL + "/Images/Header/wallet.png"}
                    alt=""
                    className=""
                  />
                </picture>
                <h2 className={` ${css.mytext}`}>Wallet</h2>
              </Link>
            </div>

            <div className="complete_kyc_btn">
              <Link
                to={
                  profile && profile.verified === `unverified`
                    ? `/Kyc2`
                    : `/Profile`
                }
              >
                <picture className="">
                  <img
                    width="32px"
                    src={process.env.PUBLIC_URL + "/Images/kyc-icon-new.png"}
                    alt=""
                    className=""
                  />
                </picture>
                <h2 className={` ${css.mytext}`}>
                  {profile && profile.verified === `unverified`
                    ? "Complete KYC"
                    : profile && profile.verified === "pending"
                    ? "In Process"
                    : profile && profile.verified === "verified"
                    ? "Completed Kyc ✅"
                    : "Completed Kyc ✅"}{" "}
                </h2>
              </Link>
            </div>
          </div>
        </div>

        <div class="battle_devider"></div>
        {profile && !profile.referral && (
          <div className="px-3 py-1 use_referal">
            <div
              className={`d-flex align-items-center ${css.position_relative}`}
              style={{ height: "84px" }}
            >
              <picture>
                <img
                  height="32px"
                  width="32px"
                  src={process.env.PUBLIC_URL + "Images/Header/sreferEarn.png"}
                  alt=""
                  className="snip-img"
                />
              </picture>
              <div className="pl-4">
                <div className="commaon_label">Use Refer Code</div>
                <div className="d-flex">
                  <input
                    type="text"
                    style={{ color: "#000" }}
                    className="commaon_input_box"
                    aria-describedby="basic-addon1"
                    value={referral}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      update_RefCode();
                    }}
                  >
                    {/* <img
                      width='20px'
                      className='ml-2'
                      src={
                        process.env.PUBLIC_URL +
                        '/Images/select-blue-checkIcon.png'
                      }
                    /> */}
                    <FaCheck style={{ color: "white", fontSize: "38px" }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className='Complete_Profile_box'>
          <h2 className='profile_headings'>Complete Profile</h2>

          <a
            href='https://youtu.be/I21O16jqpNE?si=Au_O7ErIrYc0UfW0'
            target='_blank'
          >
            <span
              className={`${css.gamesSectionHeadline} text-uppercase position-absolute  font-weight-bold guide `}
              style={{
                right: '0.5rem',
                backgroundColor: '#003441',
                color: 'white',
                borderRadius: '4px',
                padding: '2px 13px',
                fontSize: '12px'
              }}
            >
              Youtube
              <img
                className='ml-2'
                src={'/images/Homepage/youtube.png'}
                alt=''
                width='15px'
              />
            </span>
          </a>

          <Link
            className={`d-flex align-items-center  complete_kyc_btn email_verified`}
          >
            <picture className=''>
              <img
                src='/images/LandingPage_img/emailgif.gif'
                alt=''
                className=''
              />
            </picture>
            <div className='Profile_mytext__ZlZJy'>
              {profile?.Email === null ? (
                <div className='email_input'>Email Verification</div>
              ) : (
                <div>{profile?.Email}</div>
              )}
            </div>
            {profile?.Email === null ? (
              <img
                className={`ml-2`}
                width='20px'
                src='/images/icon-edit.jpg'
                alt=''
                onClick={toggleInput}
              />
            ) : profile?.Email !== null ? (
              <span onClick={toggleInput}>
                {' '}
                <img
                  className={`ml-2`}
                  width='35px'
                  src='/images/icon-edit.jpg'
                  alt=''
                  onClick={toggleInput}
                />
              </span>
            ) : (
              ''
            )}
          </Link>
          <div
            className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
          >
            {inputOpen && (
              <div>
                <input
                  aria-invalid={!isValidEmail}
                  type='email'
                  className='email_input_inner'
                  placeholder='Enter your email'
                  value={Email}
                  onChange={handleEmailChange}
                />

                <img
                  className='ml-2 profile_name_edit'
                  width='35px'
                  src='/images/select-blue-checkIcon1.png'
                  alt=''
                  onClick={() => {
                    toggleInput()
                    if (isValidEmail) {
                      UpdateEmail(Id)
                    } else {
                      Swal.fire({
                        title: 'please enter your email address',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      })
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div> */}

        {/* cash won small box */}
        <div className="profile_info_small_box_main_row">
          <h2 className="profile_headings">Other Details</h2>

          <div className="row rows">
            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-cash-won-green-circular.png"
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>Coin Won</h3>
                <h4> {profile && profile.wonAmount}</h4>
              </div>
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_3">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/global-purple-battleIcon.png"
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>Battle Played</h3>
                <h4> {total && total}</h4>
              </div>
            </div>

            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <div className="profile_info_small_box_main ">
                <div className="profile_info_small_box_inner bg_2">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/referral-signup-bonus-new.png"
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>Referral</h3>

                <h4> {profile && profile.referral_earning}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="battle_devider"></div>
        <div className="logout_div">
          <Link
            to="/login"
            // className={`${css.center_xy}  text-uppercase py-4 font-weight-bolder text-muted`}
            onClick={(e) => logout(e)}
            className="logout_btn"
          >
            Log Out <IoIosLogOut />
          </Link>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
      {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;
