import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
//import Rightcontainer from '../Components/Rightcontainer'
import '../css/layout.css'
import '../css/login.css'
import loginss from './ss.png'
import Spinner from 'react-bootstrap/Spinner'

export default function Login () {
  const history = useHistory()

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const [WebSitesettings, setWebsiteSettings] = useState('')

  const fetchData = async () => {
    const response = await fetch(baseUrl + 'settings/data')
    const data = await response.json()
    return setWebsiteSettings(data)
  }
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData()
  }, [])

  const [Phone, setPhone] = useState()
  const [twofactor_code, settwofactor_code] = useState()
  const [otp, setOtp] = useState(false)
  const [isLoading1, setIsloading1] = useState(false)

  const [secretCode, setSecretCode] = useState()
  const [referral, setReferral] = useState(useLocation().pathname.split('/')[2])

  const handleClick = async e => {
    e.preventDefault()

    if (!Phone) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter your phone number'
      })
    } else if (Phone.length !== 10) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please  enter currect phone number'
      })
    } else {
      await axios
        .post(baseUrl + `login`, {
          Phone,
          referral
        })
        .then(respone => {
          if (respone.data.status === 101) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: respone.data.msg
            })
          } else if (respone.data.status === 200) {
            setOtp(true)
            console.log(respone.data)
            setSecretCode(respone.data.secret)
            if (respone.data.myToken) {
              Swal.fire({
                icon: 'success',
                title: 'OTP',
                text: 'Demo Account Testing OTP: ' + respone.data.myToken
              })
            }
          }
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong'
            // width: '20%',
            // height:'20%',
          })
        })
    }
  }

  const varifyOtp = async e => {
    e.preventDefault()
    console.log('verify otp sumbut req')
    if (!Phone) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter your phone number'
      })
    } else {
      await axios
        .post(baseUrl + `login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode
        })
        .then(respone => {
          if (respone.data.status === 101) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: respone.data.msg
            })
          } else if (respone.data.status === 200) {
            const token = respone.data.token
            localStorage.setItem('token', token)
            window.location.reload(true)
            setTimeout(function () {
              history.push('/Games')
            }, 1000)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!'
            })
          }
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        })
    }
  }

  const setError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Invalid Number',
      confirmation: true
    })
  }

  // otp valid
  const [isValid, setIsValid] = useState(false)
  const [showResend, setShowResend] = useState(false)
  const [seconds, setSeconds] = useState(60)

  useEffect(() => {
    let timerInterval

    if (isValid && seconds > 0) {
      timerInterval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)
    } else {
      clearInterval(timerInterval)
      setShowResend(true) // Show the Resend button when OTP expires
      setIsValid(false)
    }

    return () => {
      clearInterval(timerInterval)
    }
  }, [isValid, seconds])

  const handleResendClick = e => {
    handleClick(e)
    setIsValid(true)
    setSeconds(60)
    setShowResend(false) // Hide the Resend button after clicking it
  }
  return (
    <>
      <div className='leftContainer'>
        <div className='main-area'>
          <div style={{ overflowY: 'hidden' }}>
            <div className=' w-100 center-xy mx-auto Login_card_main'>
              <div className='Login_card_inner'>
                <div className='snip-img'>
                  <img
                    src='/Images/LandingPage_img/mrludoking.png'
                    className=''
                    alt=''
                  />
                </div>
                <h2 className='main_haeding'>Sign in</h2>
                <div className=''>
                  <div className='input-group Login_input_main'>
                    <div className='country_code'>+91</div>
                    <input
                      className='input_field'
                      name='mobile'
                      type='number'
                      placeholder='Mobile number'
                      autoComplete='off'
                      // onChange={(e) => setPhone(e.target.value)}
                      onChange={e => {
                        setPhone(e.target.value)
                        if (e.target.value.length > 10) {
                          setError(true)
                        }
                      }}
                    />
                    {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                  </div>
                </div>
                {otp && (
                  <div className=''>
                    <div className='input-group Login_input_main mt-3'>
                      <div className='country_code'>OTP</div>

                      <input
                        className='input_field'
                        name='password'
                        type='tel'
                        placeholder='Enter OTP'
                        onChange={e => settwofactor_code(e.target.value)}
                      />
                      {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                    </div>
                    <div className='resend_otp_div'>
                      {isValid ? (
                        <p className='otp_expire'>
                          OTP is valid for {seconds} seconds.
                        </p>
                      ) : seconds === 0 ? (
                        <div className='otp_expire'>
                          {showResend && (
                            <button onClick={handleResendClick}>
                              Resend OTP
                            </button>
                          )}
                        </div>
                      ) : (
                        <p className='otp_expire'>
                          OTP is valid for {seconds} seconds.
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {!otp &&
                  (isLoading1 ? (
                    <button className='Login-button cxy' disabled>
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                      Loading...
                    </button>
                  ) : (
                    <button
                      className='Login-button cxy '
                      onClick={e => {
                        handleClick(e)
                        setIsValid(true)
                      }}
                    >
                      Get OTP
                    </button>
                  ))}

                {/* {!otp && (
                  <button
                    className='Login-button'
                    onClick={e => handleClick(e)}
                  >
                    Continue
                  </button>
                )}
                {otp && (
                  <button
                    className='Login-button cxy mt-4'
                    onClick={e => varifyOtp(e)}
                  >
                    Verify
                  </button>
                )} */}

                {otp &&
                  (isLoading1 ? (
                    <button className='Login-button cxy' disabled>
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                      Loading...
                    </button>
                  ) : (
                    <button
                      className='Login-button cxy'
                      onClick={e => varifyOtp(e)}
                    >
                      Verify
                    </button>
                  ))}
              </div>
              <div className='login-footer'>
                By continuing I agree that{' '}
                {WebSitesettings ? WebSitesettings.CompanyName : ''}. may store
                and process my data in accordance with the{' '}
                <Link to='/term-condition'>Terms of Use</Link>,{' '}
                <Link to='/PrivacyPolicy'>Privacy Policy</Link> and that I am 18
                years or older. I am not playing from Assam, Odisha, Nagaland,
                Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
