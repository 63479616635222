import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import upiqrcode from "upiqrcode";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import { Link } from "react-router-dom";
//import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import Swal from "sweetalert2";
// import findGif from "/";
import Header from "../Components/Header";
import Bharatpe from "../BHARATPE_QR_.png";
import Modal from "react-bootstrap/Modal";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Addcase = ({ walletUpdate }) => {
  const history = useHistory();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  const [global, setGlobal] = useState(100);
  const [next, setNext] = useState(1);
  const [process, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(false);
  const [isPhonePeActive, setPhonePeActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  // const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);
  const { checkMyPay, setCheckMyPay } = useState(false);

  const [isManualPaymentActive, setManualPaymentActive] = useState(false);
  const [isManualUPIQR, setManualUPIQR] = useState(false);

  const [RazorPayKey, setRazorPayKey] = useState(false);
  const [RazorPayAccountName, setAccountName] = useState("Skill Based Gaming");

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [upi_id, setUpi_id] = useState();
  const [paymentUpi, setPaymentUpi] = useState();
  const [upiImage, setupiImage] = useState();

  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState("");
  const [upiUtrNum, setupiUtrNum] = useState("");
  const [amount, setamount] = useState("");
  const [isLoading1, setIsloading1] = useState(false);
  const [utr, setutr] = useState("");
  const [paymentmode, setPaymentmode] = useState("");

  // Payment key

  // const paymentmode = JSON.parse(localStorage.getItem('siteSetting'))
  // console.log(data.checkedMypay);

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };
  const [show_won, setShow_won] = useState(false);
  const handleClose_won = () => setShow_won(false);
  const handleShow_won = () => setShow_won(true);
  const ManualPayment = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const formData = new FormData();

    formData.append("Transaction_Screenshot", scrnshot);
    formData.append("amount", global);
    formData.append("referenceId", upiUtrNum);

    const response = await axios.post(
      baseUrl + `manual/deposit/txn`,
      formData,
      { headers }
    );
    //console.log(response.data);
    if (response.data.status === "Pending") {
      setTimeout(() => {
        axios
          .get(baseUrl + `manual/depositstatus/${response.data._id}`, {
            headers,
          })
          .then((res) => {
            //console.log(res);
            const icon = res.data.status === "PAID" ? "success" : "danger";
            var title = "";
            if (res.data && res.data.status === "PAID") {
              title = "Deposit submited successfully";
            } else if (res.data && res.data.status === "Pending") {
              title = "Deposit Transaction in proccess.";
            } else if (!res.data.status) {
              title = "Deposit Transaction Rejected Due to invalid UTR Number.";
            }

            history.push("/");
            setTimeout(() => {
              setProcess(false);
              Swal.fire({
                title: title,
                icon: icon,
                confirmButtonText: "OK",
              });
            }, 1000);
          });
      }, 30000);
      setProcess(true);
    } else {
      setProcess(false);
      Swal.fire({
        title: "Deposit Falied",
        icon: "danger",
        confirmButtonText: "OK",
      });
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  // MYPAY GETWAY API
  const myPay = () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `mypay/payin`,
        {
          amount: global,
        },
        { headers }
      )
      .then((res) => {
        console.log(res, "res")
        setProcess(false);
        if(res.data.status==false){

          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
        else if (res.data) {

          let order_id = res.data.txnID;
          let order_token = res.data.clientrefid;
          //setProcess(false);
          window.open(`${res.data?.data?.qrstring}&am=${global}`);
          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.status,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        setProcess(false);
        console.log(e, "error");

        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: e.response.msg,
            icon: 'warning',
            confirmButtonText: 'OK'
          })
        }
        else{
          Swal.fire({
            title: 'error!! Please refresh and connect to admin',
            icon: 'warning',
            confirmButtonText: 'OK'
          })
        }
        alert(e);
      });
  };

  const settingdata = () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `settings/data`, { headers })
      .then((res) => {
        setProcess(false);
        console.log(res.data);
        setPaymentmode(res.data);
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    settingdata();
  }, []);

  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    const response = await axios.post(
      baseUrl + `user/razorpay_order`,
      {
        amount: global,
        channel: channel,
        payment_method: method,
        provider: walletOption,
        bankCode: bankCode,
        account_name: account_name,
        payment_gatway: razorpay,
      },
      { headers }
    );

    if (!response) {
      alert("Server error. please check are you onlin?");
      return;
    }

    //console.log(response.data.orderdata);
    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: RazorPayKey,
      name: RazorPayAccountName,
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        //console.log(response)
        try {
          const paymentId = response.razorpay_payment_id;

          //const url = baseUrl+`rozarpay/capture/${paymentId}`;
          //const captureResponse = await axios.post(url, {},{headers})
          //console.log(captureResponse.data);
          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaydesposit/response`,
        { order_id, order_token, order_status, paymentId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for decentrodepositeupi
  const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/decentrodepositeupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: decentropay,
        },
        { headers }
      )
      .then((res) => {
        console.log(res);
        if (res.data.data.status === "SUCCESS") {
          let order_id = res.data.txnID;
          let order_token = res.data.data.decentroTxnId;
          //setProcess(false);
          window.open(res.data.data.data.generatedLink);
          setTimeout(() => {
            //checkdecentrodeposit(order_id, order_token)
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  //use for decentrodepositeupi
  const phonePeDepositeUpi = (channel, method, upiMethod, phonepay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/phonedepositeapi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: phonepay,
        },
        { headers }
      )
      .then((res) => {
        // console.log(res.data.data.data.instrumentResponse.redirectInfo.url);
        // console.log(res.data.data.data.transactionId);
        // console.log(res.data.txnID);
        if (res.data.data.success === true) {
          let order_id = res.data.txnID;
          let order_token = res.data.data.data.transactionId;
          //setProcess(false);
          window.open(res.data.data.data.instrumentResponse.redirectInfo.url);
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            //history.push('/');
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const checkphonepedeposit = (order_id, order_token) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepestatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction in failed";

        if (res.data.status == "Pending") {
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 6000);
        } else {
          history.push("/");
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //Function to load pinelab script for the display of pinelab payment SDK.
  // function loadPinelabScript(src) {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // }

  //function will get called when clicked on the pay button.
  // async function displayPinelabFrame(channel, method, upiMethod, Phonepe) {
  //   const res2 = await loadPinelabScript(
  //     "https://checkout.pluralonline.com/v1/web-sdk-checkout.js"
  //   );

  //   if (!res2) {
  //     alert("Pinelab SDK failed to load. please check are you online?");
  //     return;
  //   }
  //   //setProcess(true);
  //   const access_token = localStorage.getItem("token")
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   // creating a new order and sending order ID to backend
  //   const response = await axios.post(baseUrl + `user/pinelab_order`, { amount: global, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, payment_gatway: pinelab }, { headers });

  //   if (!response) {
  //     alert("Server error. please check are you onlin?");
  //     return;
  //   }

  //   console.log(response.data);
  //   // Getting the order details back
  //   let order_id = response.data.txnID;
  //   let order_token = response.data.orderdata.token;
  //   const data = response.data.orderdata;

  //   const options = {
  //     theme: "default", // "default" or "black"
  //     orderToken: order_token,
  //     channelId: "WEB", // "APP" or "WEB"
  //     paymentMode: 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI',// comma separated - Example - 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI'
  //     countryCode: "91",// type = string, default "91"
  //     mobileNumber: accountPhone, // type = string, default = null
  //     emailId: account_mail_id, //default null
  //     showSavedCardsFeature: false, // type = boolean, default = true
  //     successHandler: async function (response) {
  //       console.log(response)
  //       // Handle success response
  //       const payment_id = response.payment_id
  //       const plural_order_id = response.plural_order_id
  //       checkpinelabdeposit(order_id, plural_order_id, 'SUCCESS', payment_id)
  //     },
  //     failedHandler: async function (response) {
  //       console.log(response)
  //       // Handle failure response
  //       //error_code: "401"
  //       //error_message: "Something went wrong"
  //       const payment_id = response.payment_id
  //       checkpinelabdeposit(order_id, order_token, 'FAILED', payment_id)
  //     }
  //   };
  //   const plural = new window.Plural(options);
  //   plural.open(options);
  // }

  // const checkpinelabdeposit = (order_id, order_token, order_status, paymentId) => {

  //   const access_token = localStorage.getItem("token")
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   axios.post(baseUrl + `pinelabdesposit/response`, { order_id, order_token, order_status, paymentId }, { headers })
  //     .then((res) => {
  //       const icon = res.data.status === "PAID" ? "success" : "danger";
  //       const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction Failed";
  //       history.push('/');
  //       setTimeout(() => {
  //         Swal.fire({
  //           title: title,
  //           icon: icon,
  //           confirmButtonText: "OK",
  //         });
  //       }, 1000);

  //     })
  //     .catch((e) => {
  //       if (e.response.status === 401) {
  //         localStorage.removeItem('token');
  //         localStorage.removeItem('token');
  //         window.location.reload()
  //       }
  //     })
  // }

  //use for upigatway
  const depositUpiGateway = (channel, method, upiMethod, upigateway) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.status) {
          // console.log(res.data.data.data.payment_url)
          // console.log(res.data.data.data.order_id)
          let order_id = res.data.txnID;
          let order_token = res.data.data.data.order_id;
          //setProcess(false);
          window.open(res.data.data.data.payment_url);
          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const depositUpiGateway1 = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi1`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.status) {
          // console.log(res.data.data.data.payment_url)
          // console.log(res.data.data.data.order_id)
          let order_id = res.data.txnID;
          let order_token = res.data.data.data.order_id;
          //setProcess(false);
          window.open(res.data.data.data.payment_url);
          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const checkupideposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for cashfree gatway
  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/deposite`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.payment_method === "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (res.data.data.payment_method === "netbanking") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (
          res.data.data.channel === "link" &&
          res.data.data.payment_method === "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
          window.location.href = res.data.data.data.payload[upiMethod];
        } else if (
          res.data.data.channel === "qrcode" &&
          res.data.data.payment_method === "upi"
        ) {
          setQrCode(res.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkout = (paymentID) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `checkout/deposite/txn`,
        { txnID: paymentID },
        { headers }
      )
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus === "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        } else if (res.data.txnStatus === "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        }
        // else if(res.data.txnStatus!="PENDING")
        // {
        //     clearInterval(checkInterval);
        //     Swal.fire({
        //       title: res.data.msg,
        //       icon: 'info',
        //       confirmButtonText: "OK",
        //   });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        setAccount_mail_id(res.data.Email);
        setAccount_name(res.data.holder_name);
        setAccountPhone(res.data.Phone);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });

    axios
      .get(baseUrl + `website/setting`)
      .then((res) => {
        // console.log(res, "setting");
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(res.data.isDecentroActive);
        setPhonePeActive(res.data.isPhonePeActive);
        setRazorPayKey(res.data.RazorPayKey);
        setAccountName(res.data.AccountName);
        setManualPaymentActive(res.data.isManualPaymentActive);
        // setUpiGatewayActive(res.data.isUpiGatewayActive);
        setManualUPIQR(res.data.isManualUPIQR);
        setUpi_id(res.data.upi_account_name);
        setPaymentUpi(res.data.paymentUPI);
        setupiImage(res.data.upi_image);
      })
      .catch((e) => {
        setCashFreeActive(false);
        setRazorPayActive(false);
        setDecentroActive(false);
        setPhonePeActive(false);
        setRazorPayKey(false);
        setManualPaymentActive(false);
        setManualUPIQR(false);
        // setUpiGatewayActive(false);
        // setCheckMyPay(false)
        setAccountName("Skill Based Gaming");
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  // QR CODE

  const [urls, seturls] = useState();
  const [appUrl, setAppUrl] = useState();
  const trid = Math.floor(Math.random() * 900000000) + 100000000;
  // console.log(trid)
  const payeeVPA = "9530306043890@paytm";
  const payeeName = "   Mrludo Enterprises";

  useEffect(() => {
    upiqrcode({
      payeeVPA: upi_id?.toString() || payeeVPA,
      payeeName: paymentUpi?.toString() || payeeName,
      transactionNote: "  mr Ludo",
      amount: global.toString(),
      transactionRefUrl: "https://mrludoking.com/",
      transactionId: trid,
      transactionRef: trid,
    })
      .then((upi) => {
        seturls(upi.qr); // data:image/png;base64,eR0lGODP...
        setAppUrl(upi.intent); // upi://pay?pa=Pratyaymustafi@paytm&pn=Pratyay..
      })
      .catch((err) => {
        console.log(err);
      });
    // alert(trid)
  }, [paymentUpi, global, upi_id]);

  function Openapp() {
    // alert(global)
    window.location.replace(
      `upi://pay?cu=INR&pa=BHARATPE.8000567491@fbpe&pn=J K ENTERPRISES&am=${global}&mam=10&tr=1694322072000&tn=Pay To J K ENTERPRISES`
    );
  }

  const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(payeeVPA);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "UPI ID Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const ManualPayments = () => {
    const access_token = localStorage.getItem("token");

    if (utr) {
      setIsloading1(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access_token}`);

      var formdata = new FormData();
      // formdata.append('Transaction_Screenshot', scrnshot)
      formdata.append("amount", global);
      formdata.append("referenceId", utr);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://api.mrludoking.com/manual/deposit/txn",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsloading1(false);

          if (result.status === "Pending") {
            Swal.fire({
              title: result.message,
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: result.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          history.push("/");
          handleClose_won();
        })

        .catch((error) => console.log("error", error));
    } else {
      Swal.fire({
        title: "Enter a valid UTR number",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // =============manual payment=============
  const [amountSS, setAmountSS] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("phonepay");
  const payId = "merchant1441697.augp@aubank";

  const payName = "Night King LUdo ";

  const handlePayment = () => {
    if (global === "" || global === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter an amount!",
      });
      return;
    } else if (global < 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Minimum amountSS required is 50!",
      });
      return;
    } else if (paymentMethod === "" || paymentMethod === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please choose a payment method!",
      });
      return;
    }

    const txn = Math.random().toString(36).substr(2, 20);
    const phonePe = `phonepe://pay?pa=${payId}&pn=${payName}&tid=${txn}&tr=${txn}&am=${global}&cu=INR&tn=Night King Ludo`;
    const paytm = `paytmmp://pay?pa=${payId}&pn=${payName}&tid=${txn}&tr=${txn}&am=${global}&cu=INR&tn=Night King Ludo`;
    const gPay = `tez://upi/pay?pa=${payId}&pn=${payName}&tid=${txn}&tr=${txn}&am=${global}&cu=INR&tn=Night King Ludo`;

    if (paymentMethod === "phonepay") {
      window.location.href = phonePe;
    } else if (paymentMethod === "googlepay") {
      window.location.href = gPay;
    } else if (paymentMethod === "paytm") {
      window.location.href = paytm;
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  return (
    <>
      <Header user={userAllData} />
      {
        //userAllData && userAllData.verified === 'verified' &&
        <div
          className="leftContainer bg-white"
          style={{ minHeight: "100vh", height: "100%", paddingTop: "90px" }}
        >
          {Boolean(!process) && (
            <div>
              {Boolean(next === 1) && (
                <div className="px-4  py-5">
                  <div className={`${css.games_section}`}>
                    <div className="d-flex position-relative align-items-center">
                      <h4> Choose amount to add</h4>
                    </div>
                  </div>

                  <div>
                    <label className="commaon_label">Enter Amount</label>
                  
                    <input
                      className="commaon_input_box"
                      type="tel"
                      id="amountInput"
                      style={{ width: "100%", fontSize: "2em" }}
                      value={`${global}`}
                      onChange={(e) => {
                        e.target.value > 0
                          ? e.target.value <= 25000
                            ? setGlobal(parseInt(e.target.value))
                            : setGlobal(25000)
                          : e.target.value < 0
                          ? setGlobal(10)
                          : setGlobal(0);
                      }}
                    />
                    <div class="add_amount_min_max">
                      <p> Min: 10, Max: 100000</p>
                    </div>
                  </div>

                  <div className="pb-3 ">
                    <div className="add_fund_box_main">
                      <div
                        className={`${css.gameCard_container}`}
                        onClick={() => {
                          // console.log(100);
                          setGlobal(100);
                        }}
                      >
                        <div className={`${css.add_fund_box}`}>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <div
                              className={`${css.collapseCard_title} mr-1`}
                              // style={{ fontSize: '0.9em' }}
                            >
                              ₹
                            </div>
                            <div
                              className={`${css.collapseCard_title}`}
                              // style={{ fontSize: '1.5em' }}
                            >
                              100
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${css.gameCard_container}`}
                        onClick={() => {
                          // console.log(250);
                          setGlobal(250);
                        }}
                      >
                        <div className={`${css.add_fund_box}`}>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <div
                              className={`${css.collapseCard_title} mr-1`}
                              // style={{ fontSize: '0.9em' }}
                            >
                              ₹
                            </div>
                            <div
                              className={`${css.collapseCard_title}`}
                              // style={{ fontSize: '1.5em' }}
                            >
                              250
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${css.gameCard_container}`}
                        onClick={() => {
                          // console.log(500);
                          setGlobal(500);
                        }}
                      >
                        <div className={`${css.add_fund_box}`}>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <div
                              className={`${css.collapseCard_title} mr-1`}
                              // style={{ fontSize: '0.9em' }}
                            >
                              ₹
                            </div>
                            <div
                              className={`${css.collapseCard_title}`}
                              // style={{ fontSize: '1.5em' }}
                            >
                              500
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${css.gameCard_container}`}
                        onClick={() => {
                          // console.log(1000);
                          setGlobal(1000);
                        }}
                      >
                        <div className={`${css.add_fund_box}`}>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <div
                              className={`${css.collapseCard_title} mr-1`}
                              // style={{ fontSize: '0.9em' }}
                            >
                              ₹
                            </div>
                            <div
                              className={`${css.collapseCard_title}`}
                              // style={{ fontSize: '1.5em' }}
                            >
                              1000
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${css.refer_footer}`}>
                    <div className="d-grid gap-2 col-12 mx-auto">
                      <button
                        type="button"
                        className={`${css.block}  rounded text-white font-weight-bold text-uppercase`}
                        onClick={() => {
                          global >= 10
                            ? setNext(2)
                            : alert("Minimum deposit is 10");
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {Boolean(next === 2) && (
                <div className="px-4 py-3">
                  <div className="pb-3">
                    <div className={`${css.games_section}`}>
                      <div className="d-flex position-relative align-items-center justify-content-between">
                        <div
                          className={`${css.games_section_title}`}
                          style={{ fontSize: "1.1em" }}
                        >
                          Amount to be added ₹<span>{global}</span>
                        </div>
                        <button
                          type="button"
                          onClick={() => setNext(1)}
                          className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "rgb(241, 241, 241)",
                      width: "100%",
                      height: "10px",
                      position: "absolute",
                      left: "0",
                      right: "0",
                    }}
                  ></div>
                  <div className="d-flex flex-column mt-4">
                    <div className="games-section-title">Pay Through UPI</div>
                    {Boolean(paymentmode.checkedMypay) && (
                      <>
                        <div
                          onClick={() => {
                            myPay();
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#850000",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                                background: "#fff",
                                paddingRight: "10px",
                                borderTopLeftRadius: "7px",
                                borderBottomLeftRadius: "7px",
                              }}
                            >
                              <img
                                width="40px"
                                src="UPI.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  // paddingBottom: "10px",
                                  // paddingLeft: "3px",
                                  // paddingTop: "5px",
                                  backgroundColor:"#fff"
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong className="text-light">Paynow</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>

                          <div
                            className={`${css.gameCard_container}`}
                            onClick={() => {
                              // console.log(250);
                              setGlobal(250);
                            }}
                          ></div>
                        </div>
                      </>
                    )}
                    {/* {Boolean(paymentmode.isUpiGatewayActive) && (
                      <>
                        <div>
                          {global <= 300 ? (
                            <>
                              <div
                                onClick={() => {
                                  depositUpiGateway(
                                    "link",
                                    "upi",
                                    "upipay",
                                    "upigateway"
                                  );
                                }}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px" }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#314c65",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      textAlign: "center",
                                      background: "#fff",
                                      paddingRight: "10px",
                                      borderTopLeftRadius: "7px",
                                      borderBottomLeftRadius: "7px",
                                    }}
                                  >
                                    <img
                                      width="40px"
                                      src="UPI.png"
                                      alt=""
                                      style={{
                                        marginLeft: "7px",
                                        paddingBottom: "10px",
                                        paddingLeft: "3px",
                                        paddingTop: "5px",
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center flex-column ml-4">
                                    <div className="jss30">
                                      <strong className="text-light">
                                        Paynow 1
                                      </strong>
                                    </div>
                                    <div className="jss31"></div>
                                  </div>
                                </div>

                                <div
                                  className={`${css.gameCard_container}`}
                                  onClick={() => {
                                    // console.log(250);
                                    setGlobal(250);
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : (
                            <div>
                              <div
                                onClick={handleShow_won}
                                className="add-fund-box mt-3"
                                style={{
                                  paddingTop: "0px",
                                  height: "60px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#314c65",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      textAlign: "center",
                                      background: "#fff",
                                      paddingRight: "10px",
                                      borderTopLeftRadius: "7px",
                                      borderBottomLeftRadius: "7px",
                                    }}
                                  >
                                    <img
                                      width="40px"
                                      src="UPI.png"
                                      alt=""
                                      style={{
                                        marginLeft: "7px",
                                        // paddingBottom: "10px",
                                        // paddingLeft: "3px",
                                        // paddingTop: "5px",
                                        backgroundColor:"#fff"
                                        
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center flex-column ml-4">
                                    <div className="jss30">
                                      <strong className="text-light">
                                        Pay Manual
                                      </strong>
                                    </div>
                                    <div className="jss31"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="-body mt-3">
                                <div className="  text-dark alrt_msg_div alrt_msg_div_paynow">
                                  <p>
                                    {" "}
                                    सभी यूजर्स को सूचित किया जाता है की 300 ₹ से
                                    अधिक वाले पेमेंट में आपको UTR नंबर डालना
                                    होगा यूजर्स के पास पेमेंट करने के बाद एक UTR
                                    नंबर दिखाई देगा उसको कॉपी करके UTR बॉक्स में
                                    डालकर submit कर देना है उसके बाद आपका पेमेंट
                                    ऑटोमेटिक ऐड हो जाएगा |
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )} */}
                   
                    {Boolean(isRazorPayActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          displayRazorpayPaymentSdk(
                            "link",
                            "upi",
                            "upipay",
                            "razorpay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                // paddingBottom: "10px",
                                // paddingLeft: "3px",
                                // paddingTop: "5px",
                                backgroundColor:"#fff"
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Deposit Here</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isPhonePeActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          phonePeDepositeUpi(
                            "link",
                            "upi",
                            "upipay",
                            "Phonepe"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#850000",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                // paddingBottom: "10px",
                                // paddingLeft: "3px",
                                // paddingTop: "5px",
                                backgroundColor:"#fff"
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong style={{color:"#fff"}}>Deposit PhonePe</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isDecentroActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          decentroDepositeUpi(
                            "link",
                            "upi",
                            "upipay",
                            "decentropay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#850000",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4 mb-5">
                            <div className="jss30">
                              <strong>Desposit Now</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* manual UTR start */}
                    <Modal
                      show={show_won}
                      onHide={handleClose_won}
                      className="cancel_popup_reason_popup"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="manual_payment_data_box">
                          {/* <div style={{ textAlign: 'center' }}>
                            <img
                              src={upiImage}
                              className='img-fluid'
                              style={{ maxWidth: '150px' }}
                            />
                          </div> */}

                          <div className="form-group">
                            <lable className="label"> Enter Amount</lable>
                            <input
                              className="commaon_input_box mb-2 form-control"
                              type="number"
                              required
                              value={global}
                              onChange={(e) => setGlobal(e.target.value)}
                              placeholder="Enter amount"
                              readOnly
                            />
                          </div>
                          <div className="payment_method_img">
                            <div>
                              <input
                                type="radio"
                                className="payment_method"
                                name="payment_method"
                                value="phonepay"
                                checked={paymentMethod === "phonepay"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="phonepay"
                                className=" radio_label"
                              >
                                Phone Pay
                              </label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                className="payment_method"
                                name="payment_method"
                                value="googlepay"
                                checked={paymentMethod === "googlepay"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label
                                htmlFor="googlepay"
                                className=" radio_label"
                              >
                                Google Pay
                              </label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                className="payment_method"
                                name="payment_method"
                                value="paytm"
                                checked={paymentMethod === "paytm"}
                                onChange={handlePaymentMethodChange}
                              />
                              <label htmlFor="paytm" className=" radio_label">
                                Paytm
                              </label>
                            </div>
                          </div>
                          <button
                            type="button"
                            id="pay"
                            className="btn btn-primary btn-lg w-100 mb-3"
                            onClick={handlePayment}
                          >
                            Pay
                          </button>

                          <div className="form-group">
                            <label className="label mt-4">
                              Enter UTR Number
                            </label>
                            <input
                              className="commaon_input_box form-control"
                              type="text"
                              required
                              value={utr}
                              onChange={(e) => setutr(e.target.value)}
                              placeholder="Enter UTR Number"
                            />
                          </div>

                          <button
                            type="submit"
                            value="Submit"
                            className="btn btn-danger mt-3 text-white results_btn w-100"
                            id="post"
                            onClick={ManualPayments}
                          >
                            Submit
                          </button>

                          <div className="collapseCard-body mt-3">
                            <div className=" collapseCard-text text-dark alrt_msg_div">
                              <h5 className=" text-primary ">
                                पेमेंट होने के बाद नीचे दी गई फोटो की तरह आपके
                                पास भी UTR नंबर आएंगे जिनको ऊपर दिए गए UTR बॉक्स
                                में डालकर कर submit कर देना है आपका पेमेंट
                                ऑटोमेटिक ऐड हो जाएगा !!
                              </h5>
                            </div>
                          </div>
                          <div className="payment_method_img">
                            <a href="utr1.png" target="_blank">
                              <img
                                width="150px"
                                src="utr1.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </a>
                            <a href="utr2.png" target="_blank">
                              <img
                                width="150px"
                                src="utr2.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </a>
                            <a href="utr3.jpeg" target="_blank">
                              <img
                                width="150px"
                                src="utr3.jpeg"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </a>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/* end */}
                    {Boolean(isMobile) && Boolean(isCashFreeActive) && (
                      <div>
                        <div
                          onClick={() => {
                            method.current = 'gpay'
                            deposit('link', 'upi', 'gpay')
                          }}
                          className='add-fund-box mt-3'
                          style={{ paddingTop: '0px', height: '60px' }}
                        >
                          <div
                            className='d-flex align-items-center'
                            style={{
                              backgroundColor: '#fafafa',
                              border: '1px solid #e0e0e0',
                              borderRadius: '7px'
                            }}
                          >
                            <div
                              className='d-flex align-items-center'
                              style={{
                                height: '60px',
                                display: 'flex',
                                textAlign: 'center'
                              }}
                            >
                              <img
                                width='40px'
                                src='gpay-logo.png'
                                alt=''
                                style={{
                                  marginLeft: '7px',
                                  paddingBottom: '10px',
                                  paddingLeft: '3px',
                                  paddingTop: '5px'
                                }}
                              />
                            </div>
                            <div className='d-flex justify-content-center flex-column ml-4'>
                              <div className='jss30'>
                                <strong>G-Pay</strong>
                              </div>
                              <div className='jss31'></div>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            method.current = 'phonepe'
                            deposit('link', 'upi', 'phonepe')
                          }}
                          className='add-fund-box mt-3'
                          style={{ paddingTop: '0px', height: '60px' }}
                        >
                          <div
                            className='d-flex align-items-center'
                            style={{
                              backgroundColor: '#fafafa',
                              border: '1px solid #e0e0e0',
                              borderRadius: '7px'
                            }}
                          >
                            <div
                              className='d-flex align-items-center'
                              style={{
                                height: '60px',
                                display: 'flex',
                                textAlign: 'center'
                              }}
                            >
                              <img
                                width='40px'
                                src='/phonepe-logo.png'
                                alt=''
                                style={{
                                  marginLeft: '7px',
                                  paddingBottom: '10px',
                                  paddingLeft: '3px',
                                  paddingTop: '5px'
                                }}
                              />
                            </div>
                            <div className='d-flex justify-content-center flex-column ml-4'>
                              <div className='jss30'>
                                <strong>PhonePe</strong>
                              </div>
                              <div className='jss31'></div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            method.current = 'paytm'
                            deposit('link', 'upi', 'paytm')
                          }}
                          className='add-fund-box mt-3'
                          style={{ paddingTop: '0px', height: '60px' }}
                        >
                          <div
                            className='d-flex align-items-center'
                            style={{
                              backgroundColor: '#fafafa',
                              border: '1px solid #e0e0e0',
                              borderRadius: '7px'
                            }}
                          >
                            <div
                              className='d-flex align-items-center'
                              style={{
                                height: '60px',
                                display: 'flex',
                                textAlign: 'center'
                              }}
                            >
                              <img
                                width='40px'
                                src='/paytm-logo.png'
                                alt=''
                                style={{
                                  marginLeft: '7px',
                                  paddingBottom: '10px',
                                  paddingLeft: '3px',
                                  paddingTop: '5px'
                                }}
                              />
                            </div>
                            <div className='d-flex justify-content-center flex-column ml-4'>
                              <div className='jss30'>
                                <strong>Paytm UPI</strong>
                              </div>
                              <div className='jss31'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(!isMobile) && Boolean(isCashFreeActive) && (
                      <div
                        className='add-fund-box mt-3'
                        onClick={() => {
                          deposit('qrcode', 'upi')
                        }}
                        style={{ paddingTop: '0px', height: '60px' }}
                      >
                        <div
                          className='d-flex align-items-center'
                          style={{
                            backgroundColor: '#fafafa',
                            border: '1px solid #e0e0e0',
                            borderRadius: '7px'
                          }}
                        >
                          <div
                            className='d-flex align-items-center'
                            style={{ height: '60px' }}
                          >
                            <img
                              width='45px'
                              src='/qr-scan.png'
                              alt=''
                              style={{
                                marginLeft: '7px',
                                paddingLeft: '3px'
                              }}
                            />
                          </div>
                          <div className='d-flex justify-content-center flex-column ml-4'>
                            <div className='jss30'>
                              <strong>Scan QR Code</strong>
                            </div>
                            <div className='jss31'></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div className="games-section-title mt-3">
                        Other Options
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div
                        className="add-fund-box mt-3"
                        onClick={() => {
                          setNext(4);
                        }}
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <img
                              width="45px"
                              src="all-wallets.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Other Wallets</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div
                        className="add-fund-box mt-3"
                        onClick={() => {
                          setNext(5);
                        }}
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <img
                              width="45px"
                              src="/bank1.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Net Banking</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* ////// */}
                </div>
              )}
            </div>
          )}
          {Boolean(next === 3) && (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <img src={qrCode} alt="img" />
            </div>
          )}
          {Boolean(next === 4) && (
            <div className="px-4 py-3">
              <div className="pb-3">
                <div className={`${css.games_section}`}>
                  <div className="d-flex position-relative align-items-center justify-content-between">
                    <div
                      className={`${css.games_section_title}`}
                      style={{ fontSize: "1.1em" }}
                    >
                      Amount to be added ₹<span>{global}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setNext(1)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "rgb(241, 241, 241)",
                  width: "100%",
                  height: "10px",
                  position: "absolute",
                  left: "0",
                  right: "0",
                }}
              ></div>
              <div className="d-flex flex-column mt-4">
                <div className="games-section-title">Pay Through</div>
                <div
                  className="add-fund-box mt-3"
                  style={{ paddingTop: "0px", height: "60px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px" }}
                    >
                      <img
                        width="45px"
                        src="all-wallets.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Other Wallets</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                    <div className="d-flex justify-content-center ml-auto mr-3">
                      <button
                        type="button"
                        onClick={() => setNext(2)}
                        className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <select
                className="form-control mt-4"
                style={{
                  border: "1px solid rgb(224, 224, 224)",
                  outline: "none",
                  borderRadius: "5px",
                  background: "rgb(250, 250, 250)",
                  height: "3rem",
                }}
                onChange={(e) => {
                  setWalletOption(e.target.value);
                }}
              >
                <option value="airtel">Airtel Money</option>
                <option value="freecharge">Freecharge</option>
                <option value="mobikwik">Mobikwik</option>
                <option value="ola">Ola Money</option>
                <option value="jio">Reliance Jio Money</option>
              </select>
              <div className={`${css.refer_footer}`}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                    onClick={() => {
                      deposit("link", "app");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
          {Boolean(next === 5) && (
            <div className="px-4 py-3">
              <div className="pb-3">
                <div className={`${css.games_section}`}>
                  <div className="d-flex position-relative align-items-center justify-content-between">
                    <div
                      className={`${css.games_section_title}`}
                      style={{ fontSize: "1.1em" }}
                    >
                      Amount to be added ₹<span>{global}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setNext(1)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "rgb(241, 241, 241)",
                  width: "100%",
                  height: "10px",
                  position: "absolute",
                  left: "0",
                  right: "0",
                }}
              ></div>
              <div className="d-flex flex-column mt-4">
                <div className="games-section-title">Pay Through</div>
                <div
                  className="add-fund-box mt-3"
                  style={{ paddingTop: "0px", height: "60px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px" }}
                    >
                      <img
                        width="45px"
                        src="/bank1.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Net Banking</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                    <div className="d-flex justify-content-center ml-auto mr-3">
                      <button
                        type="button"
                        onClick={() => setNext(2)}
                        className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <select
                className="form-control mt-4"
                style={{
                  border: "1px solid rgb(224, 224, 224)",
                  outline: "none",
                  borderRadius: "5px",
                  background: "rgb(250, 250, 250)",
                  height: "3rem",
                }}
                onChange={(e) => {
                  setBankCode(e.target.value);
                }}
              >
                <option value="3003">Axis Bank</option>
                <option value="3005">Bank of Baroda - Retail Banking</option>
                <option value="3006">Bank of India</option>
                <option value="3007">Bank of Maharashtra</option>
                <option value="3009">Canara Bank</option>
                <option value="3010">Catholic Syrian Bank</option>
                <option value="3011">Central Bank of India</option>
                <option value="3012">City Union Bank</option>
                <option value="3016">Deutsche Bank</option>
                <option value="3017">DBS Bank Ltd</option>
                <option value="3018">DCB Bank - Personal</option>
                <option value="3019">Dhanlakshmi Bank</option>
                <option value="3020">Federal Bank</option>
                <option value="3021">HDFC Bank</option>
                <option value="3022">ICICI Bank</option>
                <option value="3023">IDBI Bank</option>
                <option value="3024">IDFC FIRST Bank</option>
                <option value="3026">Indian Bank</option>
                <option value="3027">Indian Overseas Bank</option>
                <option value="3028">IndusInd Bank</option>
                <option value="3029">Jammu and Kashmir Bank</option>
                <option value="3030">Karnataka Bank Ltd</option>
                <option value="3031">Karur Vysya Bank</option>
                <option value="3032">Kotak Mahindra Bank</option>
                <option value="3033">
                  Laxmi Vilas Bank - Retail Net Banking
                </option>
                <option value="3037">Punjab & Sind Bank</option>
                <option value="3038">
                  Punjab National Bank - Retail Net Banking
                </option>
                <option value="3039">RBL Bank</option>
                <option value="3040">Saraswat Bank</option>
                <option value="3042">South Indian Bank</option>
                <option value="3043">Standard Chartered Bank</option>
                <option value="3044">State Bank Of India</option>
                <option value="3052">Tamilnad Mercantile Bank Ltd</option>
                <option value="3054">UCO Bank</option>
                <option value="3055">Union Bank of India</option>
                <option value="3058">Yes Bank Ltd</option>
                <option value="3060">Bank of Baroda - Corporate</option>
                <option value="3061">Bank of India - Corporate</option>
                <option value="3062">DCB Bank - Corporate</option>
                <option value="3064">Lakshmi Vilas Bank - Corporate</option>
                <option value="3065">Punjab National Bank - Corporate</option>
                <option value="3066">State Bank of India - Corporate</option>
                <option value="3067">Union Bank of India - Corporate</option>
                <option value="3071">Axis Bank Corporate</option>
                <option value="3072">Dhanlaxmi Bank Corporate</option>
                <option value="3073">ICICI Corporate Netbanking</option>
                <option value="3074">Ratnakar Corporate Banking</option>
                <option value="3075">Shamrao Vithal Bank Corporate</option>
                <option value="3076">Equitas Small Finance Bank</option>
                <option value="3077">Yes Bank Corporate</option>
                <option value="3079">Bandhan Bank- Corporate banking</option>
                <option value="3080">
                  Barclays Corporate- Corporate Banking - Corporate
                </option>
                <option value="3081">Indian Overseas Bank Corporate</option>
                <option value="3083">City Union Bank of Corporate</option>
                <option value="3084">HDFC Corporate</option>
                <option value="3086">Shivalik Bank</option>
                <option value="3087">AU Small Finance</option>
                <option value="3088">Bandhan Bank - Retail Net Banking</option>
                <option value="3041">Shamrao Vitthal Co-operative Bank</option>
                <option value="3051">Tamil Nadu State Co-operative Bank</option>
                <option value="3089">Utkarsh Small Finance Bank</option>
                <option value="3090">
                  The Surat People’s Co-operative Bank Limited
                </option>
                <option value="3091">
                  Gujarat State Co-operative Bank Limited
                </option>
                <option value="3092">HSBC Retail Netbanking</option>
                <option value="3102">Jana Small Finance Bank</option>
              </select>
              <div className={`${css.refer_footer}`}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                    onClick={() => {
                      deposit("link", "netbanking");
                    }}
                  >
                    Add
                  </button>
                 
                </div>
              </div>
            </div>
          )}
          {Boolean(process) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src="/images/LandingPage_img/loader1.gif"
                style={{ width: "100%" }}
                alt="img"
              />
            </div>
              
          )}
        </div>
      }
    </>
  );
};
export default Addcase;
