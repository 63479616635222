import React, { memo } from 'react'
import css from '../Modulecss/Home.module.css'
import { Link } from 'react-router-dom'
import acceptSound from './accept.mp3'
import findGif from "../css/loading_old.gif"
// import Find2 from "../css/"
import playSound from './play.mp3'
import { MdOutlineDelete } from 'react-icons/md'

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
const nodeMode = process.env.NODE_ENV
if (nodeMode === 'development') {
  var baseUrl = beckendLocalApiUrl
} else {
  baseUrl = beckendLiveApiUrl
}
const BetCard = React.memo(
  ({
    allgame,
    user,
    deleteChallenge,
    getPost,
    RejectGame,
    winnAmount,
    AcceptChallang,
    updateChallenge
  }) => {
    // if(user == allgame.Created_by._id && allgame.Status == "new"){
    //   setTimeout(() => {
    //     alert('bettle timeout');
    //     deleteChallenge(allgame._id);
    //   }, 60000);
    // }

    return (
      <div
        className={`${css.betCard} mt-2  battle_card_main card_bottom_border`}
      >
        <span
          className={`${css.betCardTitle}  d-flex align-items-center  ${css.betTitleDiv} headings`}
        >
          Challange From
          <span className='ml-1' style={{ color: 'brown' }}>
            {allgame.Created_by.Name}
          </span>
          {user == allgame.Created_by._id && allgame.Status == 'new' && (
            <button
              className={`  ml-auto delete_buttons`}
              onClick={() => deleteChallenge(allgame._id)}
            >
              <MdOutlineDelete className='card_icons' />
            </button>
          )}
          {/* start and reject button */}
          {user == allgame.Created_by._id && allgame.Status == 'requested' && (
            <div className='d-flex ml-auto align-items-center'>
              <Link
                to={{
                  pathname: `/viewgame1/${allgame._id}`,
                  state: { prevPath: window.location.pathname }
                }}
                onClick={e => getPost(allgame._id)}
                style={{ bottom: '0' }}
              >
                <button
                  className={`bg-success position-relative mx-1 btn-sm text-white btn-inverse-success`}
                >
                  START
                </button>
              </Link>
              <button
                className={`text-white bg-danger position-relative mx-1 btn-sm btn-outline-youtube`}
                onClick={() => RejectGame(allgame._id)}
                style={{ bottom: '0' }}
              >
                REJECT
              </button>
            </div>
          )}
        </span>

        <div className='d_flex_center'>
          {/* Entry Fee */}
          <div className=' pb-1'>
            <span className={css.betCardSubTitle}>Entry Fee</span>
            <div>
              {/* <img
                src={
                  process.env.PUBLIC_URL +
                  '/Images/LandingPage_img/global-rupeeIcon.png'
                }
                alt=''
                width='21px'
              /> */}
              <span className={css.betCardAmount}>
                {' '}
                <span className='mr-1'>₹</span> {allgame.Game_Ammount}
              </span>
            </div>
          </div>
          {/* middle buttons */}
          <div>
            {user !== allgame.Created_by._id && allgame.Status == 'new' && (
              <button
                className='samll_btn  battle_plat_btn play_btn_color'
                onClick={() => AcceptChallang(allgame._id)}
              >
                Play
              </button>

            )}
            {user == allgame.Created_by._id && allgame.Status == 'new' && (
              <div className='text-center '>
                <div className='text-center'>
                  <img
                    src="/Loader.gif"
                    style={{ width: '30px', height: '30px' }}
                  />
                </div>
                <div style={{ lineHeight: 1 }}>
                  <span className={css.betCard_playerName}>
                    Finding Player!
                  </span>
                  
                </div>
              </div>
            )}
            <div className=''>
              {user !== allgame.Created_by._id &&
                allgame.Status == 'requested' && (
                  <div className='d-flex ml-auto align-items-center'>
                    <div
                      className={`${css.bgSecondary} ${css.playButton} ${css.cxy} position-relative mx-1 text-white btn-sm samll_btn`}
                    >
                      requested
                    </div>
                    <button
                      className={`${css.bgSecondary} ${css.playButton} ${css.cxy} position-relative mx-1 bg-danger btn-sm samll_btn`}
                      onClick={() => RejectGame(allgame._id)}
                    >
                      cancel
                    </button>
                  </div>
                )}
            </div>
            {user !== allgame.Created_by._id && allgame.Status == 'running' && (
              <div className='d-flex ml-auto align-items-center'>
                <audio src={playSound} autoPlay></audio>
                <Link
                  className={`samll_btn start_btn_color'`}
                  to={{
                    pathname: `/viewgame1/${allgame._id}`,
                    state: { prevPath: window.location.pathname }
                  }}
                  onClick={e => updateChallenge(allgame._id)}
                >
                  start
                </Link>
              </div>
            )}
            {user == allgame.Created_by._id && allgame.Status == 'requested' && (
              <div className='d-flex ml-auto align-items-center '>
                <audio src={acceptSound} autoPlay></audio>

                <div className='text-center col'>
                  <div className='pl-2'>
                    {allgame.Accepetd_By.avatar ? (
                      <img
                        src={baseUrl + `${allgame.Accepetd_By.avatar}`}
                        alt=''
                        width='40px'
                        height='40px'
                        style={{
                          borderTopLeftRadius: '50%',
                          borderTopRightRadius: '50%',
                          borderBottomRightRadius: '50%',
                          borderBottomLeftRadius: '50%',
                          marginTop: '5px'
                        }}
                      />
                    ) : (
                      <img
                        src={`https://nightking.com/user.png`}
                        alt=''
                        width='40px'
                        height='40px'
                        style={{
                          borderTopLeftRadius: '50%',
                          borderTopRightRadius: '50%',
                          borderBottomRightRadius: '50%',
                          borderBottomLeftRadius: '50%',
                          marginTop: '5px'
                        }}
                      />
                    )}
                  </div>
                  <div style={{ lineHeight: 1 }}>
                    <span className={css.betCard_playerName}>
                      {allgame.Accepetd_By.Name}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Prize */}
          <div>
            <span className={css.betCardSubTitle}>Winning</span>
            <div>
              {/* <img
                src={
                  process.env.PUBLIC_URL +
                  '/Images/LandingPage_img/global-rupeeIcon.png'
                }
                alt=''
                width='21px'
              /> */}
              <span className={css.betCardAmount}>
                <btton className="bet_card_button" >
                <span className='mr-2'>₹</span>
                {allgame.Game_Ammount + winnAmount(allgame.Game_Ammount)}
                </btton>
                {/* <span className='mr-1'>₹</span>
                {allgame.Game_Ammount + winnAmount(allgame.Game_Ammount)} */}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default memo(BetCard)
