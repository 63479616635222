import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import css from '../css/Addcase.module.css'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import Rightcontainer from '../Components/Rightcontainer'
import Swal from 'sweetalert2'

const Redeem = ({ walletUpdate }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }
  const history = useHistory()

  const [amount, setamount] = useState()
  const [userAllData, setUserAllData] = useState()
  const role = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios
      .get(baseUrl + `me`, { headers })
      .then(res => {
        //console.log(res.data);
        setUserAllData(res.data)
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
        }
      })
  }

  useEffect(() => {
    let access_token = localStorage.getItem('token')
    access_token = localStorage.getItem('token')
    if (!access_token) {
      window.location.reload()
      history.push('/login')
    }
    role()
  }, [])

  const deposit = () => {
    if (amount && amount >= 95 && amount <= 100000) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      axios
        .post(baseUrl + `referral/to/wallet`, { amount }, { headers })
        .then(res => {
          walletUpdate()
          if (res.data.msg === 'Invalid Amount') {
            Swal.fire({
              title: "You don't have sufficient amount",
              icon: 'warning',
              confirmButtonText: 'OK'
            })
          } else {
            Swal.fire({
              title: 'success',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(e => {
          alert(e)
          if (e.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location.reload()
            history.push('/login')
          }
        })
    } else {
      let msg = 'Enter Amount'
      if (!amount) {
        msg = 'Enter Amount'
      } else if (95 <= amount <= 100000) {
        msg = 'Amount should be more than 95.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  return (
    <>
      <Header user={userAllData} />
      <div className='leftContainer '>
        <div className='mt-5 Orher_page_main_section'>
          <div className=''>
            <div className='profile_headings mt-5 pt-3'>
              Redeem your refer balance
            </div>
            <p className='commaon_para mt-2'>
              TDS (0%) will be deducted after annual referral earning of
              <img
                className='mx-1'
                src={
                  process.env.PUBLIC_URL +
                  '/Images/LandingPage_img/global-rupeeIcon.png'
                }
                alt=''
                width='20x'
                height='20x'
              />{' '}
              15,000.
            </p>
          </div>
          <div className='add_amount_min_max'>
            <p> Enter Amount (Min: 95, Max: 10000)</p>
          </div>
          <div>
            <div className='MuiFormControl-root MuiTextField-root mt-4 '>
              <input
                class='commaon_input_box'
                type='number'
                style={{ width: '100%' }}
                value={amount}
                placeholder='Enter Amount'
                onChange={e => setamount(parseInt(e.target.value))}
              ></input>
              <div className='add_amount_min_max'>
                <p>
                  Minimum withdrawal amount{' '}
                  <img
                    className='mx-1'
                    src={
                      process.env.PUBLIC_URL +
                      '/Images/LandingPage_img/global-rupeeIcon.png'
                    }
                    alt=''
                    width='20x'
                    height='20x'
                  />{' '}
                  95
                </p>
              </div>
              <p className='commaon_para'>
                Money will be added to your wallet.
              </p>
            </div>
            <div className='add_cash_nest_btn'>
              <button onClick={() => deposit()}>Redeem</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Redeem
