import React, { useEffect, useState, useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Homepage from './uiComponents/Homepage'

import Landing from './uiComponents/Landing'
import userLogin from './uiComponents/Login'
import Mywallet from './uiComponents/Mywallet'
import Addcase from './uiComponents/Addcase'

import Withdrawopt from './uiComponents/Withdrawopt'
import Profile1 from './uiComponents/Profile1'
import ViewGame1 from './uiComponents/ViewGame1'
import Gamehistory from './uiComponents/Gamehistory'
import 'animate.css'
import axios from 'axios'

import Transactionhistory from './uiComponents/Transactionhistory'
import Referralhis from './uiComponents/Referralhis'
import Refer from './uiComponents/Refer'
import Notification from './uiComponents/Notification'
import Support from './uiComponents/Support'

// import Games from './uiComponents/Games';
// import Kyc from './uiComponents/Kyc';
import Kyc2 from './uiComponents/Kyc2'
// import kyc3 from './uiComponents/kyc3';
import RefundPolicy from './uiComponents/RefundPolicy'
import terms_condition from './uiComponents/terms_condition'
import PrivacyPolicy from './uiComponents/PrivacyPolicy'
import Gamerules from './uiComponents/Gamerules'
import ResponsibleGaming from './uiComponents/ResponsibleGaming'
import Return from './uiComponents/Return'
import Notify from './uiComponents/Notify'
import Header from './Components/Header'
import Rightcontainer from './Components/Rightcontainer'
// import Downloadbutton from "./Components/Downloadbutton";
import Redeem from './uiComponents/Redeem'
import AboutUs from './uiComponents/AboutUs'
import Maintenence from './uiComponents/Maintenence'
// import socket from "./Components/";
import { IoIosLogOut } from 'react-icons/io'

const App2 = () => {
  //const [mount, setMount] = useState(true)
  //const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const [WebSitesettings, setWebsiteSettings] = useState('')
  const [settinData, setSettingData] = useState(undefined)
  const [socket, setSocket] = useState()
  const [sideMaintenance, setSideMaintenance] = useState('')
  const [Id, setId] = useState('')
  const userID = useRef()
  const isMounted = useRef(true)

  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const fetchData = async () => {
    const response = await fetch(baseUrl + 'settings/data')
    const data = await response.json()
    return setSideMaintenance(data.siteMaintenance)
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }))
    }
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {}
      this._socketListeners[eventName] = callback
    }
    let socket = new WebSocket('wss://socket.mrludoking.com/server')
    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    // socket.emit("websetting");

    function openFunc () {
      socket.onopen = () => {
        console.log('websocket is connected 👍')
        setSocket(socket)
        socket.pingTimeout = setTimeout(() => {
          socket.close()
          setSocket(undefined)
        }, 30000 + 1000)
      }
    }

    socket.emit('settings')
    function listenFunc () {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data)
          socket._socketListeners[event](data)
        } catch (error) {
          console.log(error)
        }
      }

      socket.listen('ping', data => {
        socket.emit('pong', 2)

        clearTimeout(socket.pingTimeout)
        socket.pingTimeout = setTimeout(() => {
          console.log('ping terminate works 🏩')
          socket.close()
          setSocket(undefined)
        }, 30000 + 1000)
      })

      socket.listen('settings', data => {
        console.log(data, 'data')
        localStorage.setItem('siteSetting', JSON.stringify(data))

        setSideMaintenance(data.siteMaintenance)
      })
    }

    const data = JSON.parse(localStorage.getItem('siteSetting'))
    // console.log(data.CompanyEmail)
    function closeFunc () {
      socket.onclose = () => {
        // console.log('socket disconnected wow 😡')
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout)
          setSocket(undefined)
          socket = new WebSocket('wss://socket.mrludoking.com/server')
          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc()
          listenFunc()
          closeFunc()
        }
      }
    }
    openFunc()
    listenFunc()
    closeFunc()

    return () => {
      isMounted.current = false
      clearTimeout(socket.pingTimeout)
      setSocket(undefined)
      socket.close()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   socket.emit("websetting");
  // }, []);

  const access_token = localStorage.getItem('token')
  const [user, setUser] = useState()
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get(baseUrl + `me`, { headers })
      .then(res => {
        setUser(res.data)
      })
      .catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('token')
          // history.pushState("/login")
        }
      })
  }
  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {sideMaintenance === true ? (
        <div>
          <Maintenence />
        </div>
      ) : (
        <>
          <div className='leftContainer'>
            <div>
              <Header user={user} />
              <div className='whatsap_btn'>
                <a href='https://wa.me/+919216175374' target='_blank'>
                  {' '}
                  <img src='/whatsapp.png' />
                </a>
                {/* {JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined" ? (
                    ""
                  ) : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.whatsappSupport ? (
                    <>
                      <a
                        href={
                          JSON.parse(localStorage.getItem("sitSetting")) ==
                          "undefined"
                            ? ""
                            : JSON.parse(localStorage.getItem("sitSetting"))
                                ?.whatsappSupport
                        }
                        target="_blank"
                      >
                        <img
                          width="50px"
                          src={process.env.PUBLIC_URL + "/whatsapp.png"}
                          alt=""
                        />
                      </a>
                    </>
                  ) : (
                    ""
                  )} */}
              </div>
            </div>
          </div>

          {!access_token ? (
            <Switch>
              <Route
                path='/addcase'
                render={() => <Addcase walletUpdate={getUser} />}
              />
              <Route exact path='/' component={Landing} />
              <Route path='/landing' component={Landing} />
              <Route path='/login' component={userLogin} />
              <Route path='/RefundPolicy' component={RefundPolicy} />
              <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
              <Route path='/term-condition' component={terms_condition} />
              <Route path='/about' component={AboutUs} />
              <Route path='/refund-policy' component={RefundPolicy} />
              <Route path='/contact-us' component={Support} />
              <Route path='/Gamerules' component={Gamerules} />
              <Route path='/responsible-gaming' component={ResponsibleGaming} />
              <Redirect to='/login' />
            </Switch>
          ) : (
            <Switch>
              <Route
                path='/transaction-history'
                component={Transactionhistory}
              />
              {/* <Route exact path="/transaction" component={Transactionhistory} /> */}
              <Route exact path='/Referral-history' component={Referralhis} />
              <Route exact path='/landing' component={Landing} />
              <Route exact path='/Gamehistory' component={Gamehistory} />
              {/* <Route exact path="/profile" component={Profile} /> */}
              <Route
                exact
                path='/HomePage/:Game'
                render={() => <Homepage walletUpdate={getUser} />}
              />
              <Route exact path='/refer' component={Refer} />
              <Route exact path='/Notification' component={Notification} />
              <Route exact path='/' component={Landing} />
              <Route path='/profile' component={Profile1} />
              <Route path='/viewgame1/:id' component={ViewGame1} />
              <Route
                path='/addcase'
                render={() => <Addcase walletUpdate={getUser} />}
              />

              <Route
                path='/Withdrawopt'
                render={() => <Withdrawopt walletUpdate={getUser} />}
              />
              <Route path='/wallet' component={Mywallet} />
              <Route path='/support' component={Support} />

              {/* <Route path="/Games" component={Games} /> */}
              <Route exact path='/landing/:id' component={Landing} />
              <Route path='/kyc2' render={() => <Kyc2 user={user} />} />
              <Route path='/Rules' component={Gamerules} />
              <Route path='/RefundPolicy' component={RefundPolicy} />
              <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
              <Route path='/term-condition' component={terms_condition} />
              {/* <Route path="/timer" component={Timer}/> */}
              <Route
                path='/return'
                render={() => <Return walletUpdate={getUser} />}
              />
              <Route path='/notify' component={Notify} />

              <Route
                path='/Redeem'
                render={() => <Redeem walletUpdate={getUser} />}
              />
              <Route path='/contact-us' component={Support} />
              <Route path='/refund-policy' component={RefundPolicy} />
              <Route path='/Gamerules' component={Gamerules} />
              <Route path='/responsible-gaming' component={ResponsibleGaming} />
              <Route path='/about' component={AboutUs} />

              <Redirect to='/landing' />
            </Switch>
          )}
          <div className='rightContainer'>
            <Rightcontainer />
          </div>

          {/* <h2>Install Demo</h2> */}
        </>
      )}
    </>
  )
}
export default App2
