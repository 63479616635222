import React from 'react'
import maint from '../Maintenance.svg'
// import '../css/maint.css'
const Maintenence = () => {
  return (
    <div
      className='leftContainer mb_space'
      // style={{ minHeight: '100vh', height: '100%' }}
    >
      <div className='pt-5 mt-5  Orher_page_main_section'>
        <div
          className='bg_maint mb-4'
          style={{ backgroundImage: "url('../Images/web_maint.jpg')" }}
        ></div>
        <div className='bg_cont'>वेबसाइट पर पैसे निकालने और डालने में जो परेशानी आपको हो रही है उसको दूर करने के लिए कुछ समय के लिए वेबसाइट को मेंटेनेंस पर रखा गया है जल्द ही लौटेंगे नई अपडेट के साथ  !!
 आपका पैसा सुरक्षित है विश्वास बनाए रखिए |
</div>
      </div>
    </div>
  )
}

export default Maintenence