import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Link
  //    NavLink, useHistory, useLocation
} from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
//import Swal from "sweetalert2";
import '../css/landing.css'
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from '../Components/Downloadbutton'
import Header from '../Components/Header'
import { Carousel } from "react-bootstrap";

export default function Landing () {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  var baseUrl
  if (nodeMode === 'development') {
    baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }
  const [open, setOpen] = useState(false)
  const [userAllData, setUserAllData] = useState()
  const [WebSitesettings, setWebsiteSettings] = useState('')
  const fetchData = async () => {
    const response = await fetch(baseUrl + 'settings/data')
    const data = await response.json()
    return setWebsiteSettings(data)
  }

  const role = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios
      .get(baseUrl + `me`, { headers })
      .then(res => {
        setUserAllData(res.data)
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      })
  }

  useEffect(() => {
    let access_token = localStorage.getItem('token')
    access_token = localStorage.getItem('token')
    if (!access_token) {
      //window.location.reload()
    }
    role()
    fetchData()
  }, [])

  return (
    <>
      <Header user={userAllData} />

      <div className='leftContainer'>
        <div className='main-area' style={{ paddingTop: '80px' }}>
          <div className='collapseCard-container'>
            <div className='collapseCard'>
              <Link to={'/refer'} style={{ textDecoration: 'none' }}>
                <div className='collapseCard-body'>
                  {/* <div className=' collapseCard-text text-dark alrt_msg_div'>
                    {' '}
                    <h5 className=' '> {WebSitesettings?.msg} </h5>
                  </div> */}
                  <div className="main_slider">
          <Carousel fade controls={false}>
            <Carousel.Item>
              <img src="/banners1.png" className="img-fluid img_banner" />
              
            </Carousel.Item>
            <Carousel.Item>
              <img src="/banners2.png" className="img-fluid img_banner"  />

              
            </Carousel.Item>
            {/* <Carousel.Item>
              <img src="/banners1.png" className="img-fluid img_banner" />

              
            </Carousel.Item> */}
          </Carousel>
          </div>
                </div>
              </Link>
            </div>
          </div>

          {/*<div className="gameCard pt-2 " >
                    <h5 className="d-none pt-2  text-danger d-block text-center">
                    ◉ बैंक सर्वर की वजह से डिपोजिट मै समस्या आ रही है जो अगले 24 से 48 घंटे मै सॉल्व हो जायेगी।
                    </h5>
                </div>
                
                <div className="gameCard pt-2 " >
                    
                    <h5 className="d-none pt-2  text-success d-block text-center">
                    <a className="text-success" href="https://api.whatsapp.com/send?phone=+918290429001&text=Hello">
                        ◉ किसी भी समस्या के लिए 8290429001 पर whatsapp मैसेज और कॉल करें।
                    </a>
                    </h5>
                    
                </div>*/}

          <section className='games-section p-3'>
            <div className='d-flex align-items-center games-section-title'>
              Our Tournaments
            </div>
            <div className='games-section-headline mt-2 mb-1'>
              <div className='games-window '>
                {/* =================Ludo Classic Auto================ */}

                <Link
                  className='gameCard-container'
                  to={`/Homepage/Ludo%20Classics`}
                >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        '/Images/LandingPage_img/dashboard/ludoclassicauto.png'
                      }
                      alt=''
                    />
                  </picture>
                </Link>

                {/* =================Ludo Classics manual================ */}

                <Link
                  className='gameCard-container'
                  to={`Homepage/ludoClassicManual`}
                >
                  {/* <Link className="gameCard-container" to={``}> */}
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        '/Images/LandingPage_img/dashboard/ludoclassicmanual.png'
                      }
                      alt=''
                    />
                  </picture>
                </Link>

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%201%20Goti`}> */}

               
             
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}

                {/* =================Ludo Snake================ */}
                <Link className='gameCard-container' to={`#`}>
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        '/Images/LandingPage_img/dashboard/snake.png'
                      }
                      alt=''
                    />
                  </picture>
                </Link>

              
              </div>
            </div>
          </section>

          <div className='downloadButton'>
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  )
}
