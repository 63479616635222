import React, { useEffect, useState, useRef } from 'react'
import '../css/viewGame1.css'
import '../css/layout.css'
import css from '../css/Pan.module.css'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Components/Header'
//import Rightcontainer from '../Components/Rightcontainer';
import Swal from 'sweetalert2'
import '../css/Loader.css'
import Modal from 'react-bootstrap/Modal'
import { Chip } from '@mui/material'
import $ from 'jquery'
import Compressor from 'compressorjs'
import imageCompression from 'browser-image-compression'
import Spinner from 'react-bootstrap/Spinner'
import WarningSound from "../uiComponents/audio1.mp3";

export default function ViewGame1 (props) {
  const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const location = useLocation()
  const path = location.pathname.split('/')[2]

  const [Game, setGame] = useState()
  const [status, setStatus] = useState(null)
  const [fecthStatus, setFecthStatus] = useState()
  const [scrnshot, setScrnshot] = useState(null)
  const [scrnshot1, setScrnshot1] = useState('') // ADDED BY TEAM
  // console.log(scrnshot);
  const [isLoading1, setIsloading1] = useState(false)

  const [reason, setReason] = useState(null)
  const [socket, setSocket] = useState()
  const [roomcode, setRoomcode] = useState('')
  let submitReq = useRef(false)
  const isMounted = useRef(true)

  const [submitProcess, setProcess] = useState(true)

  // Results buttons State???????????????????????????/

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show_won, setShow_won] = useState(false)
  const handleClose_won = () => setShow_won(false)

  const handleShow_won = () => setShow_won(true)
  const [show_lost, setShow_lost] = useState(false)
  const handleClose_lost = () => setShow_lost(false)

  const handleShow_lost = () => setShow_lost(true)
  const [isChecked, setIsChecked] = useState(false)

  const [showInput, setShowInput] = useState(false)
  const [selectedReason, setSelectedReason] = useState(null)

  const handleChangeCheckbox = () => {
    setIsChecked(!isChecked)
  }
  const handleReason1 = () => {
    setStatus('cancelled')
    setReason('No Room code')
  }
  const handleReason2 = () => {
    setReason('Not joined')
    setStatus('cancelled')
  }
  const handleReason3 = () => {
    setReason('Not Playing')
    setStatus('cancelled')
  }
  const handleReason4 = () => {
    setReason('Dont want to play')
    setStatus('cancelled')
  }
  const handleReason5 = () => {
    setReason('Opponent Abusing')
    setStatus('cancelled')
  }
  const handleReason6 = () => {
    setReason('Game not Started')
    setStatus('cancelled')
  }
  const handleReasoneChange = e => {
    setReason(e.target.value)
  }

  // cancel button active function
  $(document).ready(function () {
    $('.cancel_reason_btns_bg').click(function () {
      $('.cancel_reason_btns_bg').removeClass('nav_active')
      $(this).addClass('nav_active')
    })
  })

  const handleSetRoomCode = () => {
    const inputValue = roomcode
    if (Game.Game_type !== 'LudoSnake') {
      if (
        /^[0-9]+$/.test(inputValue) &&
        inputValue.length === 8 &&
        inputValue[0] === '0'
      ) {
        getPost()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please enter a valid 8-digit code that starts with 0.',
          showConfirmButton: true
        })
      }
    } else {
      getPost()
    }
  }

  const getPost = async () => {
    setIsloading1(true)
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios
      .patch(
        baseUrl + `challange/roomcode/${path}`,
        {
          Room_code: roomcode
        },
        { headers }
      )
      .then(res => {
        setIsloading1(false)
        if (res.data?.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: 'warning',
            confirmButtonText: 'OK'
          })
          socket.emit('challengeOngoing')
        } else {
          setIsloading1(false)
          setGame(res.data)
        }
      })
      // .then((res) => {
      //   setGame(res.data);
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: res.data?.msg,
      //   })
      //   socket.emit("challengeOngoing");
      // })
      .catch(e => {
        if (e.response?.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          window.location.reload()
          history.push('/login')
        }
      })
  }
  /// user details start

  const [user, setUser] = useState()
  const [userAllData, setUserAllData] = useState()

  const role = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios
      .get(baseUrl + `me`, { headers })
      .then(res => {
        setUser(res.data._id)
        setUserAllData(res.data)
        // // console.log(res.data._id)
        Allgames(res.data._id)
        // getCode(res.data._id)
        // setTimeout(() => {
        // }, 1000);
        // checkExpire();
        // if(!res.data.Room_join)
        // {
        // }
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          window.location.reload()
          history.push('/login')
        }
      })
  }

  /// user details end

  const [ALL, setALL] = useState()

  const Allgames = async userId => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    await axios
      .get(baseUrl + `challange/${path}`, { headers })
      .then(res => {
        if (res.data.Status == 'new' || res.data.Status == 'requested') {
          setTimeout(async () => {
            await axios
              .get(baseUrl + `challange/${path}`, { headers })
              .then(res => {
                setProcess(false)
                 if (
                  res.data.Status == 'new' ||
                  res.data.Status == 'requested'
                ) {
                  history.push(props.location.state.prevPath)
                } else {
                  setProcess(false)
                }
              })
              .catch(error => {
                console.error(error)
                history.push(props.location.state.prevPath)
              })
          },6000)
        } else {
          setProcess(false)
        }
        setALL(res.data)
        setGame(res.data)
        if (userId == res.data.Accepetd_By._id)
          setFecthStatus(res.data.Acceptor_status)

        if (userId == res.data.Created_by._id)
          setFecthStatus(res.data.Creator_Status)
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          window.location.reload()
          history.push('/login')
        }
      })
  }
  const getCode = async userId => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios
      .get(baseUrl + `game/roomcode/get/${path}`, { headers })
      .then(res => {
        //setALL(res.data)
        Allgames(userId)
        if (res.data.Accepetd_By == userId && res.data.Room_code == 0) {
          setTimeout(async () => {
            window.location.reload()
          }, 10000)
        }
      })
  }
  const checkExpire = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    await axios
      .get(baseUrl + `game/roomcode/expire/${path}`, { headers })
      .then(res => {
        history.goBack()
      })
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }))
    }
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {}
      this._socketListeners[eventName] = callback
    }
    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    let socket = new WebSocket('wss://socket.mrludoking.com/server')
    function openFunc () {
      socket.onopen = () => {
        console.log('websocket is connected 👍')
        setSocket(socket)
        socket.pingTimeout = setTimeout(() => {
          socket.close()
          setSocket(undefined)
        }, 30000 + 1000)
      }
    }

    function listenFunc () {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data)
          if (socket._socketListeners[event])
            socket._socketListeners[event](data)
        } catch (error) {
          console.log(error)
        }
      }
      socket.listen('ping', data => {
        socket.emit('pong', 2)
        clearTimeout(socket.pingTimeout)
        socket.pingTimeout = setTimeout(() => {
          socket.close()
          setSocket(undefined)
        }, 30000 + 1000)
      })
    }
    function closeFunc () {
      socket.onclose = () => {
        console.log('socket disconnected wow 😡')
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout)
          setSocket(undefined)
          let socket = new WebSocket('wss://socket.mrludoking.com/server')
          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc()
          listenFunc()
          closeFunc()
        }
      }
    }
    openFunc()
    listenFunc()
    closeFunc()
    return () => {
      isMounted.current = false
      clearTimeout(socket.pingTimeout)
      setSocket(undefined)
      socket.close()
    }
  }, [])

  useEffect(() => {
    let access_token = localStorage.getItem('token')
    access_token = localStorage.getItem('token')
    if (!access_token) {
      window.location.reload()
      history.push('/login')
    }
    // console.log(history.location)

    role()
  }, [])

  const clearImage = e => {
    setScrnshot1(null)
    setScrnshot(null)
    setStatus(null)
  }

  // Result

  const Result = async e => {
    e.preventDefault()
    if (submitReq.current == false) {
      submitReq.current = true
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }
      if (status) {
        setProcess(true)
        const formData = new FormData()
        formData.append('file', scrnshot)
        formData.append('status', status)
        if (status == 'cancelled') {
          formData.append('reason', reason)
        }

        await axios({
          method: 'post',
          url: baseUrl + `challange/result/${path}`,
          data: formData,
          headers: headers
        })
          .then(res => {
            // socket.emit('resultAPI');
            submitReq.current = false
            setProcess(false)
            history.push(props.location.state.prevPath)
          })
          .catch(e => {
            console.log(e)
            if (e.response?.status == 401) {
              localStorage.removeItem('token')
              localStorage.removeItem('token')
              window.location.reload()
              history.push('/login')
            }
          })
      } else {
        submitReq.current = false
        alert('please fill all field or Re-Select result status')
      }
    }
  }

  const copyCode = e => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(Game.Room_code)

    Swal.fire({
      position: 'center',
      icon: 'success',
      type: 'success',
      title: 'Room Code Copied',
      showConfirmButton: false,
      timer: 1200
    })
  }
  const Completionist = () => <span>You are good to go!</span>

  // ADDED BY TEAM

  // const handleChange = async e => {
  //   e.preventDefault()
  //   const image = e.target.files[0]

  //   const options = {
  //     maxSizeMB: 0.3
  //   }

  //   try {
  //     const compressedFile = await imageCompression(image, options)
  //     setScrnshot(compressedFile)
  //     // console.log(compressedFile.size / 1024 / 1024);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    if (image && image.size / 1000 > 300) {
      console.log(" compression");
      new Compressor(image, {
        quality: 0.6,
        success(compressedResult) {
          if (compressedResult) {
            setScrnshot(compressedResult);
          } else {
            setScrnshot(image);
          }
        },
      });
    } else {
      setScrnshot(e.target.files[0]);
    }
  };
  // -----Start timer ------------------------------------------------------------------------
  const [showDiv, setShowDiv] = useState(true)
  const [secondsRemaining, setSecondsRemaining] = useState(null)
  //  console.log(secondsRemaining, "seconds remaining")

  // Replace this with the actual timestamp from MongoDB
  const createdAtTimestamp = new Date(Game?.createdAt).getTime() // Example timestamp

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const currentTime = new Date().getTime()
      const differenceInSeconds = Math.floor(
        (currentTime - createdAtTimestamp) / 1000
      )
      //  console.log(differenceInSeconds,showDiv)
      if (differenceInSeconds >= 240) {
        setShowDiv(false)
      } else {
        setSecondsRemaining(240 - differenceInSeconds)
      }
    }

    calculateTimeRemaining() // Calculate initial time remaining

    // Update time remaining every second
    const timer = setInterval(() => {
      calculateTimeRemaining()
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [createdAtTimestamp])

  // -----end timer------

  return (
    <>
      <Header user={userAllData} />
      {/* {!Game && <div class="lds-ripple"><div></div><div></div></div>} */}
      {Game && (
        <div className='leftContainer'>
          <div className='main-area' style={{ paddingTop: '100px' }}>
            {!Boolean(submitProcess) && (
              <div className='battleCard-bg'>
                <div className='battleCard'>
                  <div className='players players_card  pt-2'>
                    <div className='flex-column cxy'>
                      <h5>{Game.Created_by && Game.Created_by.Name}</h5>
                      {Game.Created_by?.avatar ? (
                        <img
                          src={baseUrl + `${Game.Created_by?.avatar}`}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = 'https://jaipurludo.com/user.png'
                          }}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{
                            borderTopLeftRadius: '50%',
                            borderTopRightRadius: '50%',
                            borderBottomRightRadius: '50%',
                            borderBottomLeftRadius: '50%'
                          }}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{
                            borderTopLeftRadius: '50%',
                            borderTopRightRadius: '50%',
                            borderBottomRightRadius: '50%',
                            borderBottomLeftRadius: '50%'
                          }}
                        />
                      )}
                    </div>
                    <img
                      className='mx-3'
                      src={
                        process.env.PUBLIC_URL + '/Images/Homepage/versus.png'
                      }
                      width='35px'
                      alt=''
                    />
                    <div className='flex-column cxy '>
                      <h5> {Game.Accepetd_By && Game.Accepetd_By?.Name}</h5>
                      {Game.Accepetd_By?.avatar ? (
                        <img
                          src={baseUrl + `${Game.Accepetd_By?.avatar}`}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{
                            borderTopLeftRadius: '50%',
                            borderTopRightRadius: '50%',
                            borderBottomRightRadius: '50%',
                            borderBottomLeftRadius: '50%'
                          }}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + `/user.png`}
                          width='50px'
                          height='50px'
                          alt=''
                          style={{
                            borderTopLeftRadius: '50%',
                            borderTopRightRadius: '50%',
                            borderBottomRightRadius: '50%',
                            borderBottomLeftRadius: '50%'
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {/* <div className='amount cxy mt-2'>
                    <h5 className='mr-3 mb-0'>Playing for</h5>
                    <img
                      className='mx-1'
                      src={
                        process.env.PUBLIC_URL +
                        '/Images/LandingPage_img/global-rupeeIcon.png'
                      }
                      width='25x'
                      alt=''
                    />
                    <h5 className='mb-0'>{Game.Game_Ammount}</h5>
                  </div> */}
                  <div className='thin-divider-x my-3' />

                  <div className='test timer_box'>
                    <div className='battle_card_main'>
                      {(Game.Room_code == null && (
                     <div className=' cxy flex-column'>
                     <h6 className='headings'>
                       {' '}
                       Set Room Code{' '}
                     </h6>
                     <h6 className='headings'>
                       लूडो किंग से रूम कोड अपलोड करें
                     </h6>

                     <input
                       type='number'
                       className='form-control mt-1 w-75'
                       style={{
                         backgroundColor: '#e8eeee',
                         border: '1px solid red'
                       }}
                       value={roomcode}
                       onChange={e =>
                         setRoomcode(e.target.value)
                       }
                     />
                     {isLoading1 ? (
                       <button
                         className='Login-button cxy mt-2'
                         disabled
                         style={{ height: '35px' }}
                       >
                         <Spinner
                           as='span'
                           animation='grow'
                           size='sm'
                           role='status'
                           aria-hidden='true'
                         />
                         Loading...
                       </button>
                     ) : (
                       <button
                         className='bg-green playButton position-static mt-2'
                         type='button'
                         onClick={handleSetRoomCode}
                       >
                         SET ROOM CODE
                       </button>
                     )}
                   </div>
                      )) ||
                        (Game.Room_code != '0' && (
                          <div className='  '>
                            <div className='Room_code_'>
                              <h3 className='profile_headings '>Room Code</h3>
                              <h3>
                                {' '}
                                <span>{Game.Room_code}</span>
                              </h3>
                              <button
                                className='bg-green playButton position-static mt-0'
                                onClick={e => copyCode(e)}
                              >
                                Copy Code
                              </button>
                            </div>
                          </div>
                        )) ||
                        (Game.Room_code == '0' &&
                          ((Game.Created_by._id == user &&
                            Game.Room_code == '0' && (
                              <div className=' cxy flex-column'>
                                {Game.Game_type === "Ludo Classics"
                                ? (
                                  <audio src={WarningSound} autoPlay></audio>
                                ) : (
                                  ""
                                )}

                                <>
                                  {showDiv ? (
                                    <div className=' cxy flex-column'>
                                      <h6 className='headings'>
                                        {' '}
                                        Set Room Code{' '}
                                      </h6>
                                      <h6 className='headings'>
                                        लूडो किंग से रूम कोड अपलोड करें
                                      </h6>

                                      <input
                                        type='number'
                                        className='form-control mt-1 w-75'
                                        style={{
                                          backgroundColor: '#e8eeee',
                                          border: '1px solid red'
                                        }}
                                        value={roomcode}
                                        onChange={e =>
                                          setRoomcode(e.target.value)
                                        }
                                      />
                                      {isLoading1 ? (
                                        <button
                                          className='Login-button cxy mt-2'
                                          disabled
                                          style={{ height: '35px' }}
                                        >
                                          <Spinner
                                            as='span'
                                            animation='grow'
                                            size='sm'
                                            role='status'
                                            aria-hidden='true'
                                          />
                                          Loading...
                                        </button>
                                      ) : (
                                        <button
                                          className='bg-green playButton position-static mt-2'
                                          type='button'
                                          onClick={handleSetRoomCode}
                                        >
                                          SET ROOM CODE
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>

                                <div
                                  className='pt-4 text-primary'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '20px'
                                  }}
                                >
                                  {showDiv ? (
                                    <div className='reaming_time'>
                                      <p>
                                        Remaining Time :{' '}
                                        <span style={{ fontSize: '30px' }}>
                                          {' '}
                                          {secondsRemaining}{' '}
                                        </span>
                                        seconds
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p style={{ color: 'red' }}>
                                        Your Time is Complete please Reset
                                        bettel !
                                      </p>
                                    </div>
                                  )}
                                </div>

                                {/* <div className="rules">
                                  <ol className="list-group list-group-numbered">
                                    <li
                                      className="list-group-item"
                                      style={{
                                        color: "red",
                                        fontSize: "20px !important",
                                      }}
                                    >
                                      <marquee>
                                        कृपया लूडो किंग से रुम कोड निकल कर SET
                                        ROOM CODE मे डाल दे 
                                      </marquee>
                                    </li>
                                  </ol>
                                </div> */}
                              </div>
                            )) ||
                            (Game.Accepetd_By._id == user &&
                              Game?.Room_code == '0' && (
                                <div className='roomCode cxy flex-column '>
                                  <>
                                    {showDiv ? (
                                      <>
                                     
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            marginBottom: '10px'
                                          }}
                                        >
                                          <h6 className='headings'>
                                            {' '}
                                            Waiting for Room Code{' '}
                                          </h6>
                                          <h6 className='headings'>
                                            रूम कोड का इंतजार है। :-
                                            {secondsRemaining} S
                                          </h6>

                                          <div className='lds-spinner'>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div>
                                        <p style={{ color: 'red' }}>
                                          Your Time is Complete please Reset
                                          bettel !
                                        </p>
                                      </div>
                                    )}
                                  </>

                                  
                                </div>
                              ))))}
                    </div>
                  </div>

                  <div className='cxy app-discription flex-column'>
                    <span style={{ opacity: '.8' }}>
                      {' '}
                      Play ludo game in Ludo King App
                    </span>
                    <div className='mt-2'>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.ludo.king'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          className='mr-2'
                          src={
                            process.env.PUBLIC_URL + '/Images/google-play.jpeg'
                          }
                          width='128px'
                          height='38px'
                          alt=''
                        />
                      </a>
                      <a
                        href='https://itunes.apple.com/app/id993090598'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + '/Images/app-store.jpeg'
                          }
                          width='128px'
                          height='38px'
                          alt=''
                        />
                      </a>
                    </div>
                  </div>
                  <div className='thin-divider-x my-3' />

                  <div className='rules'>
                    <h5 className='sub_haeding'>Game Rules</h5>
                    <ul className='list'>
                      <li className=''>Record every game while playing.</li>
                      <li className=''>
                        For cancellation of game, video proof is necessary.
                      </li>
                      <li className=''>
                        ◉ महत्वपूर्ण नोट:कृपया गलत गेम परिणाम अपलोड न करें,
                        अन्यथा आपके वॉलेट बैलेंस पर penalty लगाई जायगी। गलत
                        रिजल्ट अपडेट करने पर 50 रुपये पेनल्टी लगेगी।
                      </li>
                      <li className=''>
                        महत्वपूर्ण नोट: यदि आप गेम के परिणामों को अपडेट नहीं
                        करते हैं, तो आपके वॉलेट बैलेंस पर जुर्माना लगाया जाएगा।
                        रिजल्ट अपडेट नहीं करने पर 25 रुपये पेनल्टी लगेगी।
                      </li>
                    </ul>
                  </div>
                  <div className='match-status-border row'>
                    <h6 className='sub_haeding'>Match Status</h6>
                  </div>
                  <form
                    className='result-area'
                    onSubmit={e => {
                      Result(e)
                    }}
                    encType='multipart/form-data'
                  >
                    {fecthStatus !== null && fecthStatus !== undefined && (
                      <p>
                        You have already updated your battle result for{' '}
                        <h6 className='d-inline-block text-uppercase'>
                          <b>{fecthStatus}</b>
                        </h6>
                      </p>
                    )}
                    {fecthStatus === null && (
                      <>
                        {' '}
                        <p>
                          After completion of your game, select the status of
                          the game and post your screenshot below.
                        </p>
                        <div
                          className='MuiFormGroup-root radios'
                          role='radiogroup'
                          aria-label='Result'
                        >
                          <label className='MuiFormControlLabel-root hidden Mui-disabled'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled'
                              tabIndex={-1}
                              aria-disabled='true'
                            >
                              <span className='MuiIconButton-label'>
                                <input
                                  className='jss4 mr-2'
                                  name='battleResult'
                                  type='radio'
                                  defaultValue='select'
                                  defaultChecked
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </span>
                            </span>
                            <span
                              className='MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1'
                              style={{ marginTop: '3px' }}
                            >
                              (Disabled option)
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root results_btn results_btn_win results_btn_outer'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8'
                              aria-disabled='false'
                            >
                              <span className='MuiIconButton-label'>
                                <input
                                  className='jss4 mr-2'
                                  name='battleResult'
                                  type='radio'
                                  defaultValue='winn'
                                  onClick={() => {
                                    setStatus('winn')
                                    handleShow_won()
                                  }}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span
                              className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'
                              style={{ marginTop: '3px' }}
                            >
                              I Won
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root results_btn results_btn_lose results_btn_outer'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary'
                              aria-disabled='false'
                              root='[object Object]'
                            >
                              <span className='MuiIconButton-label'>
                                <input
                                  className='jss4 mr-2'
                                  name='battleResult'
                                  type='radio'
                                  defaultValue='lose'
                                  onClick={() => {
                                    setStatus('lose')

                                    handleShow_lost()
                                  }}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span
                              className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'
                              style={{ marginTop: '3px' }}
                            >
                              I Lost
                            </span>
                          </label>
                          <label className='MuiFormControlLabel-root results_btn results_btn_cancel results_btn_outer'>
                            <span
                              className='MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root'
                              aria-disabled='false'
                            >
                              <span className='MuiIconButton-label'>
                                <input
                                  className='jss4 mr-2'
                                  name='battleResult'
                                  type='radio'
                                  defaultValue='cancelled'
                                  onClick={() => {
                                    setStatus('cancelled')
                                    // alert(
                                    //   "Are you sure you want to cancel? क्या आप वाकई इसे रद्द करना चाहते हैं?"
                                    // );
                                    handleShow()
                                  }}
                                  style={{ transform: 'scale(1.5)' }}
                                />
                              </span>
                              <span className='MuiTouchRipple-root' />
                            </span>
                            <span
                              className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'
                              style={{ marginTop: '3px' }}
                            >
                              Cancel
                            </span>
                          </label>
                        </div>
                      </>
                    )}

                    {/* WIN MODELLLLL */}
                    {/* WIINNNNN MODEELLLL */}
                    <section className=''>
                      <Modal
                        show={show_won}
                        onHide={handleClose_won}
                        className='cancel_popup_reason_popup'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                        <div>
                              <h3>Upload Result</h3>

                              {status !== null &&
                                status !== "cancelled" &&
                                status !== "lose" && (
                                  <div
                                    className={`${css.doc_upload} mt-4 upload_ss_btn`}
                                  >
                                    {/* <input type="file" onChange={(e) => setScrnshot(e.target.files[0])} accept="image/*" required /> */}
                                    {/* ADDED BY TEAM */}
                                    <input
                                      type="file"
                                      onChange={handleChange}
                                      accept="image/*"
                                      required
                                    />
                                    {/* ADDED BY TEAM */}
                                    {!scrnshot && (
                                      <div className="cxy flex-column position-absolute ">
                                        <i
                                          class="fa-solid fa-arrow-up"
                                          style={{ color: "#fff" }}
                                        ></i>
                                        <div
                                          className={`${css.sideNav_text} mt-2 upload_ss_btn_name`}
                                        >
                                          Upload screenshot.
                                        </div>
                                      </div>
                                    )}
                                    {scrnshot && (
                                      <div className={css.uploaded}>
                                        <img
                                          src="/images/file-icon.png"
                                          width="26px"
                                          alt=""
                                          style={{ marginRight: "20px" }}
                                        />
                                        <div
                                          className="d-flex flex-column"
                                          style={{ width: "80%" }}
                                        >
                                          <div
                                            className={`${css.name} `}
                                            style={{ color: "#fff" }}
                                          >
                                            {scrnshot.name}
                                          </div>
                                          <div className={css.size}>
                                            {(
                                              scrnshot.size /
                                              1024 /
                                              1024
                                            ).toFixed(2)}{" "}
                                            MB
                                          </div>
                                        </div>
                                        <div className="image-block">
                                          <img
                                            src="/images/global-cross.png"
                                            width="10px"
                                            alt=""
                                            onClick={() => {
                                              clearImage();
                                              handleClose_won();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              <div style={{ width: "100%", marginTop: "20px" }}>
                                <img
                                  src={scrnshot1}
                                  style={{ width: "100%" }}
                                  className="screenshot_img"
                                />
                              </div>

                              {fecthStatus == null &&
                                fecthStatus == undefined && (
                                  <input
                                    type="submit"
                                    value="Post Submit"
                                    className="btn btn-danger mt-3 text-white results_btn w-100"
                                    id="post"
                                    onClick={(e) => {
                                      Result(e);
                                    }}
                                    disabled={!scrnshot}
                                  />
                                )}
                            </div>
                        </Modal.Body>
                      </Modal>
                    </section>

                    {/* LOST MODEL   */}

                    <section className=''>
                      <Modal
                        show={show_lost}
                        onHide={handleClose_lost}
                        className='cancel_popup_reason_popup'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <div>
                            {Game.Game_type === 'Ludo Ulta' ? (
                              <>
                                {' '}
                                <h3>Are you sure you Win this game?</h3>
                                <h3>
                                  क्या आप निश्चित हैं कि आप यह गेम जीत गए हैं?
                                </h3>
                              </>
                            ) : (
                              <>
                                {' '}
                                <h3>Are you sure you Lost this game?</h3>
                                <h3>
                                  क्या आप निश्चित हैं कि आप यह गेम हार गए हैं?
                                </h3>
                              </>
                            )}

                            <div className='checkbox_game'>
                              <input
                                type='checkbox'
                                id='loast'
                                name='loast'
                                value='Yes, lost'
                                checked={isChecked}
                                onChange={handleChangeCheckbox}
                              />
                              <label for='loast'> Yes, I Confirm</label>
                            </div>
                            {fecthStatus == null && fecthStatus == undefined && (
                              <input
                                disabled={!isChecked}
                                type='submit'
                                value='Yes, I Lost'
                                className='btn btn-danger mt-3 text-white results_btn'
                                id='post'
                                // onClick={handleClose_lost}
                                onClick={e => {
                                  Result(e)
                                }}
                              />
                            )}
                            <button
                              onClick={handleClose_lost}
                              className='no_lost_game_btn'
                            >
                              No{' '}
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </section>

                    {/* CANCEL NODEL */}

                    <section className=''>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className='cancel_popup_reason_popup'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <div>
                            <h3>We would like to know more</h3>
                            <h5>Select Reason for cancelling</h5>

                            {status !== null && status == 'cancelled' && (
                              <div>
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='No Room code'
                                  value='No Room code'
                                  id='reason1'
                                  onClick={() => {
                                    setSelectedReason('No Room code')
                                    setShowInput(false)
                                    handleReason1()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Not joined'
                                  value='Not joined'
                                  id='reason2'
                                  onClick={() => {
                                    setSelectedReason('Not Joined')

                                    setShowInput(false)

                                    handleReason2()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Not Playing'
                                  value='Not Playing'
                                  id='reason3'
                                  onClick={() => {
                                    setSelectedReason('Not Playing')

                                    setShowInput(false)
                                    handleReason3()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Dont Want to Play'
                                  value='Dont Want to Play'
                                  id='reason3'
                                  onClick={() => {
                                    setSelectedReason('Dont Want Play')

                                    setShowInput(false)
                                    handleReason4()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Opponent Abusing'
                                  value='Opponent Abusing'
                                  id='reason3'
                                  onClick={() => {
                                    setSelectedReason('Opponent Abusing')

                                    setShowInput(false)
                                    handleReason5()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Game not Started'
                                  value='Game not Started'
                                  id='reason3'
                                  onClick={() => {
                                    setSelectedReason('Game Not Started')

                                    setShowInput(false)
                                    handleReason6()
                                  }}
                                />
                                <Chip
                                  className='cancel_reason_btns_bg'
                                  label='Others'
                                  value='Others'
                                  id='reason3'
                                  onClick={() => {
                                    setShowInput(true)
                                  }}
                                />
                              </div>
                            )}
                            {showInput && (
                              <div class='form-group mt-3'>
                                <textarea
                                  class='form-control border-secondary'
                                  name=''
                                  placeholder='Write your Reasone......'
                                  id=''
                                  rows='3'
                                  onChange={e => {
                                    setSelectedReason('Not Playing')

                                    setReason(e.target.value)
                                  }}
                                ></textarea>
                              </div>
                            )}

                            {fecthStatus == null && fecthStatus == undefined && (
                              <input
                                type='submit'
                                className='btn btn-danger mt-3 text-white results_btn'
                                id='post'
                                onClick={e => {
                                  if (selectedReason) {
                                    Result(e)
                                  } else {
                                    Swal.fire({
                                      position: 'center',
                                      icon: 'error',
                                      type: 'error',
                                      title: 'Please Select Reasone',
                                      showConfirmButton: true
                                    })
                                  }
                                }}
                              />
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </section>

                    {status !== null && status == 'cancelled' && (
                      <div class='form-group'>
                        {/* <textarea
                          class="form-control border-secondary"
                          name=""
                          id=""
                          rows="3"
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                          required
                        ></textarea> */}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Boolean(submitProcess) && (
        <div
          className='loaderReturn'
          style={{ zIndex: '99', minHeight: '100vh' }}
        >
          <img
            src={process.env.PUBLIC_URL + '/Images/LandingPage_img/loader1.gif'}
            style={{ width: '100%' }}
          />
        </div>
      )}
      <div class='divider-y'></div>
    </>
  )
}
